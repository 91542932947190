import React, {useState, useEffect, useMemo} from "react";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import useFormContext from "../../../../components/Provider/FormProvider/useFormContext";
import uuid from "uuid";

// types
import {Form} from "../../../../types/models/Form";
import {FormData} from "../../../../types/models/AutonoForm";
import {AppState} from "../../../../store";

// 자율양식 form component
import AutoForm from "../../autonomous/AutoForm";
import {getForms} from "../../../../actions/forms";

const CounselForm = () => {
  const params = useParams<{id: string}>(); // subEvent id
  const {initialLoad, setInitialLoad} = useFormContext();
  const dispatch = useDispatch();
  const {subEvent} = useSelector((state: AppState) => state.subEvents);
  const forms = useSelector((state: AppState) => state.forms);
  const [defaultData, setDefaultData] = useState<Form>();

  // 이미 등록한 양식이 있으면 db에서 불러옴
  useEffect(() => {
    if (subEvent?.sellerCounselForm !== undefined && subEvent.sellerCounselForm !== 0 && initialLoad) {
      dispatch(getForms(subEvent.sellerCounselForm, Number(subEvent.id)));
      setInitialLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subEvent]);

  // db에서 불러온 양식을 가공하여 자율양식 form으로 만들어줌
  useEffect(() => {
    if (forms.content !== "" && forms.content !== undefined) {
      const data: Form = {
        id: forms.id,
        type: forms.type,
        formData: JSON.parse(forms.content!),
        content: "",
        subEventId: parseInt(params.id),
      };
      setDefaultData({
        ...data,
        formData: JSON.parse(forms.content!),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forms]);

  const makeDefaultFormData = () => {
    const map: Form = {
      type: "CC", // 공통 상담일지 양식 타입
      // formData: {formData: {}, formIds: []},
      formData: createItem(),
      content: "",
      subEventId: parseInt(params.id),
    };
    // const data = [{}];
    // Object.assign(map.formData!.formData, ...data);

    return map;
  };

  const createItem = () => {
    let newForm: FormData = {formData: {}, formIds: []};
    const defaultType = ["long", "short", "short", "short"];
    const defaultTitle = ["상담 내용", "상담액(숫자만)", "추진액(숫자만)", "계약액(숫자만)"];

    defaultType.map((value, idx) => {
      const newFormId = `form-${uuid.v4()}`;
      const newItem = {
        [newFormId]: {
          id: newFormId,
          index: 0,
          type: value,
          title: defaultTitle[idx],
          explain: "",
          secretCheck: "N",
          explainCheck: "N",
          requireCheck: "N",
          explainable: true,
          secretable: true,
          requirable: true,
          selectContent: {
            formData: {
              [newFormId]: {
                id: newFormId,
                explain: "",
                index: 0,
              },
            },
            formIds: [newFormId],
          },
        },
      };
      Object.assign(newForm.formData, newItem);
      // Object.assign(newForm.formIds, ...newFormId);
      newForm.formIds.push(newFormId);
    });

    return newForm;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialData = useMemo(() => makeDefaultFormData(), [params.id]);

  return (
    <>
      {defaultData !== undefined && <AutoForm initialFormData={defaultData} />}
      {defaultData === undefined && <AutoForm initialFormData={initialData} />}
    </>
  );
};

export default CounselForm;

import React, {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams, RouteComponentProps, matchPath} from "react-router-dom";
import {AppState} from "../../../../store";
import {Column, MTableToolbar} from "material-table";

// actions
import {getApplicationMeetings} from "../../../../actions/application";
import {toggleMeetingPurpose} from "../../../../actions/subEvents";
import {allMatching, choiceMatching, allMatchingCancel, choiceMatchingCancel, matchingMailSend, getMeetingTotalCount} from "../../../../actions/meeting";

// custom ui comp
import PxTable from "../../../../components/Table/PxTable";
import PxButton from "../../../../components/Buttons/PxButton";
import ButtonTypo from "../../../../components/Typhography/ButtonTypo";
import PxGridContainer from "../../../Grid/PxGridContainer";

import moment from "moment";
import {confirmAlert} from "react-confirm-alert";
import {confirmModalOptions} from "../../../../utils/confirmModalOptions";
import {setDialog, setPxDialogAction} from "../../../../actions/dialogs";
import {FormControlLabel, Switch, TextField} from "@material-ui/core";
import styled from "styled-components";
import {useState} from "react";
import {setTableInfoLoadingAction} from "../../../../actions/tableInfo";
import {useTranslation} from "react-i18next";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PxOutlinedFormControl from "../../../Forms/PxOutlinedFormControl";
import PxSelectBox from "../../../SelectBox/PxSelectBox";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import {modifySubEventMeetingAvailable} from "../../../../actions/subEvents";
import WarningRemove from "../../../Dialogs/WaringRemove";
import MeetingTotalcount from "./MeetingTotalcount";

import ArrowbackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import DoubleArrowImg from "../../../../assets/icons/double-arrow-icon.png";

const MailSendBtn = styled.button`
  transition: all 300ms ease;
  outline: none;
  padding: 5px 10px;
  text-align: center;
  line-height: 33px;
  background-color: #e9ecef;
  border: none;
  border-radius: 5px;
  margin: 10px;
  color: #383838;
  &:hover {
    background-color: #5f4b8b;
    color: white;
  }
`;

const ModalContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 40px;
  width: max-content;
  height: 20rem;
  border-radius: 10px;
  z-index: 10;
  background-color: #fafafa;
  top: 35%;
  left: 50%;
  transform: translateX(-50%) translateY(-20%);
  box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.55);
`;

const ModalTitle = styled.h1`
  font-size: 1.875rem;
  font-weight: 700;
  align-self: center;
`;

const ModalContent = styled.h1`
  font-size: 1rem;
  align-self: center;
`;

const DoubleArrowIcon = styled.img`
  fill: currentColor;
  width: 1.2em;
  height: 1.4em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
`;

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    minWidth: 320,
  },
  tableTitle: {
    marginTop: 20,
    display: "flex",
    alignItems: "center",
  },
  gubun: {fontSize: 14, paddingRight: 10},
  outlinedFormControl: {minWidth: 120},
  stateChangeBtnText: {fontSize: "14px"},
}));

interface RowType {
  applicationType: string;
  applicationCreatedDate: string;
  applicationUpdatedDate: string;
  applicationId: string;
}

interface TableStateType {
  columns: Array<Column<RowType>>;
  data: RowType[];
}

enum MatchingType {
  TIGHT = "TIGHT",
  CONNECTION = "CONNECTION",
  RECOMMEND = "RECOMMEND",
}

const MeetingList: React.FC<RouteComponentProps> = ({match}) => {
  const [t] = useTranslation("lang", {useSuspense: false});
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [type, setType] = useState("");
  const param: {id: string} = useParams();
  const [actionData, setActionData] = useState({subEventId: param.id, type});
  const applicationMeetings = useSelector((state: AppState) => state.applications.applicationMeetings);
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const [meetingAlertModal, setMeetingAlertModal] = useState<boolean>(false);
  const [systemMailModal, setSystemMailModal] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>("");
  const [meetingDirectionValue, setMeetingDirectionValue] = useState<string>();
  const [initPage, setInitPage] = useState<number>();
  const previousRoute = useSelector((state: AppState) => state.routePath.previousPath);

  //   미팅신청/체결 on / off message
  const MEETING_CONTRACT_ON_MESSAGE = "미팅 요청 방향을 설정해주세요.";
  const MEETING_CONTRACT_OFF_MESSAGE = "참가자간 미팅 신청이 불가하며, 주최사가 미팅 관리를 합니다.";
  // 시스템메일 발송 on / off message
  const SYSTEM_MAIL_ON_MESSAGE = "자동 매칭 및 주최사가 미팅 안배시, 참가자에게 미팅 확인 메일이 발송됩니다.";
  const SYSTEM_MAIL_OFF_MESSAGE = "주최사의 미팅 관리에 시스템 메일 발송이 중단됩니다.";

  const callGetApplicationMeetingAndTotalCount = useCallback(async () => {
    await dispatch(getMeetingTotalCount(param.id));
    // await dispatch(getApplicationMeetings({ subEventId: param.id, type: type }));
    if (!applicationMeetings || applicationMeetings.content.length <= 0) dispatch(setTableInfoLoadingAction(false));
  }, [dispatch, param.id]);

  useEffect(() => {
    callGetApplicationMeetingAndTotalCount();
  }, [callGetApplicationMeetingAndTotalCount]);

  useEffect(() => {
    const isApplicationDetail = matchPath(previousRoute, {path: "/subEvent/:id/application/:seconId"});

    isApplicationDetail !== null ? setInitPage(applicationMeetings?.number) : setInitPage(0);
  }, [previousRoute]);

  // 메일발송 여부
  const getMailPostToggleAt = localStorage.getItem("mailPostToggleAt") === null ? "false" : localStorage.getItem("mailPostToggleAt")!.toString();

  const [mailPostToggleAt, setMailPostToggleAt] = React.useState(getMailPostToggleAt);
  const [userMeetAvailToggleAt, setUserMeetAvailToggleAt] = useState<boolean>(true);

  const [state, setState] = React.useState<TableStateType>({
    columns: [
      {
        title: t("meetingList.group"),
        field: "applicationType",
        cellStyle: {
          width: "100px",
          paddingRight: 0,
        },
      },
      {
        title: t("meetingList.corporateName"),
        field: "applicationName",
        cellStyle: {
          width: "calc(100%-700px)",
          paddingRight: 0,
          maxWidth: "500px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
      {
        title: t("meetingList.manager"),
        field: "applicationManager",
        cellStyle: {
          width: "100px",
          paddingRight: 0,
          maxWidth: "100px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
      {
        title: t("meetingList.apply"),
        field: "applicantCount",
        width: "120px",
        cellStyle: {
          width: "120px",
          paddingRight: 0,
        },
      },
      {
        title: t("meetingList.await"),
        field: "waitingAcceptorCount",
        width: "120px",
        cellStyle: {
          width: "120px",
          paddingRight: 0,
        },
      },
      {
        title: "미팅 거절",
        field: "disagreeCount",
        width: "120px",
        cellStyle: {
          width: "120px",
          paddingRight: 0,
        },
      },
      {
        title: t("meetingList.concluded"),
        field: "agreeCount",
        cellStyle: {
          width: "120px",
          paddingRight: 0,
        },
      },
      {
        title: t("common.registerDate"),
        field: "applicationCreatedDate",
        cellStyle: {
          width: "100px",
          paddingRight: 0,
        },
      },
      // {
      //   title: t("common.reviseDate"),
      //   field: "applicationUpdatedDate",
      //   cellStyle: {
      //     width: "100px",
      //     paddingRight: 0,
      //   },
      // },
    ],
    data: [],
  });
  useEffect(() => {
    if (subEvent) {
      if (subEvent.meetingAvailable && subEvent.meetingAvailable === "N") {
        setUserMeetAvailToggleAt(false);
      } else {
        setUserMeetAvailToggleAt(true);
      }
    }
  }, [subEvent]);

  useEffect(() => {
    let tableData: RowType[] = [];
    if (applicationMeetings && Array.isArray(applicationMeetings.content) && applicationMeetings.content.length > 0 && subEvent !== undefined) {
      applicationMeetings.content.forEach(application => {
        const rowData = {
          applicationType: application.type === "seller" ? subEvent.sgroupName : subEvent.bgroupName,
          applicationManager: application.managerName,
          applicationName: application.name,
          applicantCount: application.applicantCount,
          waitingAcceptorCount: application.waitingAcceptorCount,
          disagreeCount: application.disagreeCount,
          agreeCount: application.agreeCount,
          applicationCreatedDate: moment(application.createdDate).format("YYYY-MM-DD"),
          applicationUpdatedDate: moment(application.modifiedDate).format("YYYY-MM-DD"),
          applicationId: application.id,
        };
        tableData.push(rowData);
      });
      setState(prevCreateDate => {
        return {...prevCreateDate, data: tableData};
      });
      dispatch(setTableInfoLoadingAction(false));
    }
  }, [applicationMeetings, subEvent]);

  // next rise 미팅 목적 토글 이벤트

  const meetingPurposeOpenToggle = () => {
    dispatch(toggleMeetingPurpose(Number(subEvent!.id!)));
  };

  // 자동 매칭 결과 확인창 문구
  const matchDialogMessage = (matchData: any) => {
    let returnData: string[] = [];
    console.log(`matchData : `, matchData);
    if (matchData.length > 0) {
      matchData.map((rowData: any) => {
        returnData.push(`[${rowData.date} ${rowData.startTime} ~ ${rowData.endTime}] ${rowData.acceptorName}, ${rowData.applicantName}`);
      });
    }
    return returnData;
  };

  const allMatchHandler = async (matchingType: MatchingType) => {
    const mailPostToggleAt = localStorage.getItem("mailPostToggleAt") === null ? "false" : localStorage.getItem("mailPostToggleAt")!.toString();

    const matchingParam: any = {
      subEventId: param.id,
      matchingType: matchingType,
      mailPostToggleAt: mailPostToggleAt,
    };
    const result: any = await dispatch(allMatching(matchingParam));
    dispatch(setDialog(t("meetingList.totalNMatching", {n: result.length}), matchDialogMessage(result)));
    dispatch(getApplicationMeetings({subEventId: param.id, type: type}));
    callGetApplicationMeetingAndTotalCount();
  };

  // 전체 매칭 취소 (불가시간, 신청, 체결 초기화)
  const allMatchCancelHandler = async () => {
    const mailPostToggleAt = localStorage.getItem("mailPostToggleAt") === null ? "false" : localStorage.getItem("mailPostToggleAt")!.toString();

    const matchingParam: any = {
      subEventId: param.id,
      mailPostToggleAt: mailPostToggleAt,
    };

    const result: any = await dispatch(allMatchingCancel(matchingParam));
    callGetApplicationMeetingAndTotalCount();
    if (result === "success") dispatch(getApplicationMeetings({subEventId: param.id, type: type}));
  };

  // 선택 대상 매칭
  const choiceMatchingHandler = async (matchingDatas: any, tightOrNot: string) => {
    let applicationIdList: string[] = [];

    matchingDatas.map((matchingData: any) => {
      applicationIdList.push(matchingData.applicationId);
    });

    const mailPostToggleAt = localStorage.getItem("mailPostToggleAt") === null ? "false" : localStorage.getItem("mailPostToggleAt")!.toString();

    const matchingParam: any = {
      subEventId: param.id,
      applicationIds: applicationIdList,
      tightOrNot: tightOrNot,
      mailPostToggleAt: mailPostToggleAt,
    };

    const result: any = await dispatch(choiceMatching(matchingParam));

    dispatch(setDialog(t("meetingList.totalNMatching", {n: result.length}), matchDialogMessage(result)));
    dispatch(getApplicationMeetings({subEventId: param.id, type: type}));
  };

  // 선택 대상 매칭 취소
  const choiceMatchingCancelHandler = async (matchingDatas: any) => {
    let applicationIdList: string[] = [];

    matchingDatas.map((matchingData: any) => {
      applicationIdList.push(matchingData.applicationId);
    });

    const mailPostToggleAt = localStorage.getItem("mailPostToggleAt") === null ? "false" : localStorage.getItem("mailPostToggleAt")!.toString();

    const matchingParam: any = {
      subEventId: param.id,
      applicationIds: applicationIdList,
      mailPostToggleAt: mailPostToggleAt,
    };

    const result: any = await dispatch(choiceMatchingCancel(matchingParam));
    if (result === "success") dispatch(getApplicationMeetings({subEventId: param.id, type: type}));
  };

  // 유저미팅 on/off 스위치
  const handleUserMeetingAvailToggleAtChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (subEvent) {
      setMeetingAlertModal(true);
      setMeetingDirectionValue("Y");
      // userMeetAvailToggleAt ? setModalMessage(MEETING_CONTRACT_OFF_MESSAGE) : setModalMessage(MEETING_CONTRACT_ON_MESSAGE);
      // dispatch(
      //   modifySubEventMeetingAvailable({
      //     id: subEvent.id!
      //   })
      // );
    }
  };

  // 메일발송 여부 변경
  const handleMailPostToggleAtChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSystemMailModal(!systemMailModal);
    const clicked = event.target.checked.toString();
    setMailPostToggleAt(clicked);
    localStorage.setItem("mailPostToggleAt", clicked);
  };

  // 전체 메일 발송
  const matchingMailSendAll = () => {
    const matchingMailSendParam: any = {
      subEventId: param.id,
      mode: "all",
      targetIds: [],
    };

    confirmAlert(
      confirmModalOptions({
        title: t("meetingList.sendtheEntireConcludedMail"),
        click: async () => {
          const result: any = await dispatch(matchingMailSend(matchingMailSendParam));

          if (result === true) dispatch(setDialog(t("meetingList.mailHasBeenSent"), []));
        },
      })
    );
  };

  // 선택 메일 발송
  const matchingMailSendChoice = async (dataList: any) => {
    const applicationIds = dataList.map((data: any) => data.applicationId);

    if (applicationIds.length < 1) return;

    const matchingMailSendParam: any = {
      subEventId: param.id,
      mode: "choice",
      targetIds: applicationIds,
    };

    const result: any = await dispatch(matchingMailSend(matchingMailSendParam));
    if (result === true) dispatch(setDialog(t("meetingList.mailHasBeenSent"), []));
  };

  const allMatchCancelClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    subEvent?.mainEvent &&
      dispatch(
        setPxDialogAction({
          open: true,
          children: (
            <WarningRemove
              title={t("meetingList.canceltheEntireMatch")}
              warningMsg={t("meetingList.allMatchingHaveBeenCanceled")}
              text={subEvent.mainEvent.name}
              onClick={allMatchCancelHandler}
              submitText={t("meetingList.iUnderstoodAndDelete")}
            />
          ),
          footer: <></>,
        })
      );
  };

  const onChangeType = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    setType(e.target.value as string);
    setActionData({subEventId: param.id, type: e.target.value as string});
  };

  const meetingDirectionCompleteBtn = () => {
    setMeetingAlertModal(false);
    if (subEvent) {
      dispatch(
        modifySubEventMeetingAvailable({
          id: subEvent.id!,
          meetingDirection: subEvent.meetingAvailable == "N" ? (meetingDirectionValue as string) : "N",
        })
      );
    }
  };

  const meetingDirectionOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMeetingDirectionValue(e.target.value);
  };

  const meetingAndSystemMailModal = () => {
    return (
      <>
        <ModalContainer>
          <ModalTitle>알림</ModalTitle>
          {meetingAlertModal && (
            <>
              <ModalContent>{subEvent?.meetingAvailable === "N" ? MEETING_CONTRACT_ON_MESSAGE : MEETING_CONTRACT_OFF_MESSAGE}</ModalContent>
              {subEvent?.meetingAvailable === "N" ? <MeetingDirection /> : null}
              <PxButton backgroundcolor="purple" onClick={() => meetingDirectionCompleteBtn()}>
                <ButtonTypo>확인</ButtonTypo>
              </PxButton>
            </>
          )}

          {systemMailModal && (
            <>
              <ModalContent>{localStorage.getItem("mailPostToggleAt") === "true" ? SYSTEM_MAIL_ON_MESSAGE : SYSTEM_MAIL_OFF_MESSAGE}</ModalContent>
              <PxButton backgroundcolor="purple" onClick={() => setSystemMailModal(false)}>
                <ButtonTypo>확인</ButtonTypo>
              </PxButton>
            </>
          )}
        </ModalContainer>
      </>
    );
  };

  const MeetingDirection = () => {
    const MeetingDirectionWrap = styled.div`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 25rem;
      height: 31.25rem;
      border: 1px solid #5f4b8b;
      border-radius: 10px;
      padding: 10px;
      margin: 10px 0 15px 0;
    `;

    const MeetingDirectionItem = styled.div`
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 25rem;
      height: 2.5rem;
      /* padding-left: 20px; */
    `;

    const MeetingDircetionTitle = styled.h1`
      font-size: 0.875rem;
      /* max-width: */
      margin: 0;
    `;

    const MeetingDirectionRadio = styled.input``;

    return (
      <MeetingDirectionWrap>
        <MeetingDirectionItem>
          <MeetingDircetionTitle>{subEvent?.sgroupName}</MeetingDircetionTitle>
          <DoubleArrowIcon src={DoubleArrowImg} />
          <MeetingDircetionTitle>{subEvent?.bgroupName}</MeetingDircetionTitle>
          <MeetingDirectionRadio name="meetingDirection" type="radio" value={"Y"} onChange={meetingDirectionOnChange} defaultChecked />
        </MeetingDirectionItem>
        <MeetingDirectionItem>
          <MeetingDircetionTitle>{subEvent?.sgroupName}</MeetingDircetionTitle>
          <ArrowbackIcon />
          <MeetingDircetionTitle>{subEvent?.bgroupName}</MeetingDircetionTitle>
          <MeetingDirectionRadio name="meetingDirection" type="radio" value={"seller"} onChange={meetingDirectionOnChange} />
        </MeetingDirectionItem>
        <MeetingDirectionItem>
          <MeetingDircetionTitle>{subEvent?.sgroupName}</MeetingDircetionTitle>
          <ArrowForwardIcon />
          <MeetingDircetionTitle>{subEvent?.bgroupName}</MeetingDircetionTitle>
          <MeetingDirectionRadio name="meetingDirection" type="radio" value={"buyer"} onChange={meetingDirectionOnChange} />
        </MeetingDirectionItem>
      </MeetingDirectionWrap>
    );
  };

  return (
    <>
      <div
        style={{
          display: "inline-block",
          width: "100%",
          margin: "10px",
        }}
      >
        <div
          style={{
            float: "left",
            margin: "10px",
          }}
        >
          <PxButton
            backgroundcolor="purple"
            onClick={() => {
              confirmAlert(
                confirmModalOptions({
                  title: "전체 매칭을 하시겠습니까?",
                  message: "카테고리와 키워드 모두 맞는 상대를 매칭합니다",
                  click: () => allMatchHandler(MatchingType.TIGHT),
                })
              );
            }}
          >
            <ButtonTypo>{t("meetingList.fullMatching(Tight)")}</ButtonTypo>
          </PxButton>{" "}
          <PxButton
            backgroundcolor="purple"
            onClick={() => {
              confirmAlert(
                confirmModalOptions({
                  title: "전체 매칭을 하시겠습니까?",
                  message: "카테고리가 맞는 상대를 매칭합니다",
                  click: () => allMatchHandler(MatchingType.CONNECTION),
                })
              );
            }}
          >
            {/* <ButtonTypo>{t("meetingList.fullMatching")}</ButtonTypo> */}
            <ButtonTypo>연관매칭</ButtonTypo>
          </PxButton>{" "}
          <PxButton
            backgroundcolor="purple"
            onClick={() => {
              confirmAlert(
                confirmModalOptions({
                  title: "전체 매칭을 하시겠습니까?",
                  message: "카테고리가 맞는 상대를 매칭합니다",
                  click: () => allMatchHandler(MatchingType.RECOMMEND),
                })
              );
            }}
          >
            <ButtonTypo>추천매칭</ButtonTypo>
          </PxButton>{" "}
          <PxButton backgroundcolor="purple" onClick={allMatchCancelClick}>
            <ButtonTypo>{t("meetingList.cancelFullMatching")}</ButtonTypo>
          </PxButton>
        </div>
        <div>
          <div style={{float: "right"}}>
            <span style={{fontWeight: "bold", marginRight: 10}}>{t("meetingList.meetingOnOffSwitch")}</span>
            <FormControlLabel control={<Switch checked={userMeetAvailToggleAt} onChange={handleUserMeetingAvailToggleAtChange} color="primary" />} label={userMeetAvailToggleAt ? "on" : "off"} />

            <span style={{fontWeight: "bold", marginRight: 10, marginLeft: 10}}>{t("meetingList.mailSwitch")}</span>
            <FormControlLabel
              // control={<Switch checked={mailPostToggleAt === "true" ? true : false} onChange={handleMailPostToggleAtChange} color="primary" />}
              control={
                <Switch
                  checked={mailPostToggleAt === "true" ? true : false}
                  onChange={e => {
                    handleMailPostToggleAtChange(e);
                  }}
                  color="primary"
                />
              }
              label={mailPostToggleAt === "true" ? "on" : "off"}
            />
            <MailSendBtn
              style={{
                outline: "none",
              }}
              onClick={matchingMailSendAll}
            >
              {t("meetingList.mailSending")}
            </MailSendBtn>
          </div>
        </div>
      </div>
      <MeetingTotalcount sGroupName={subEvent?.sgroupName || "seller"} bGroupName={subEvent?.bgroupName || "buyer"} />

      <PxTable<RowType>
        components={{
          Toolbar: props => {
            return (
              <PxGridContainer direction="column">
                {/* <TitleTypo style={{ paddingLeft: 10 }}>title</TitleTypo> */}
                <MTableToolbar {...props} />
              </PxGridContainer>
            );
          },
        }}
        title={
          <div className={classes.tableTitle}>
            <span className={classes.gubun}>{t("applicationList.group")}</span>
            <PxOutlinedFormControl className={classes.outlinedFormControl}>
              <PxSelectBox value={type} onChange={onChangeType} displayEmpty input={<OutlinedInput margin="dense" />}>
                <MenuItem value={""}>{t("applicationList.all")}</MenuItem>
                <MenuItem value={"seller"}>{subEvent?.sgroupName}</MenuItem>
                <MenuItem value={"buyer"}>{subEvent?.bgroupName}</MenuItem>
              </PxSelectBox>
            </PxOutlinedFormControl>
          </div>
        }
        columns={state.columns}
        getAction={getApplicationMeetings}
        totalPage={applicationMeetings?.totalPages}
        totalElements={applicationMeetings?.totalElements}
        currentPage={initPage}
        actionData={actionData}
        data={state.data}
        onRowClick={(evt, selectedRow) => {
          history.push(`${match.url}/${selectedRow?.applicationId}`);
        }}
        // material-table 속성: https://material-table.com/#/docs/features/search
        options={{
          toolbar: true,
          actionsColumnIndex: -1,
          pageSize: 10,
          showTitle: false,
          defaultExpanded: false,
          search: true,
          selection: true,
          searchFieldAlignment: "right",
          exportButton: false, // csv 다운
        }}
        actions={[
          {
            tooltip: "",
            icon: () => <span style={{fontSize: "14px"}}>{t("meetingList.matching(tight)")}</span>,
            onClick: (evt, data: any) => {
              confirmAlert(
                confirmModalOptions({
                  title: t("meetingList.selectedAutoMatching"),
                  message: t("meetingList.correctMatchForBothCategoriesAndKeywords"),
                  click: () => choiceMatchingHandler(data, "tight"),
                })
              );
            },
          },
          {
            tooltip: "",
            icon: () => <span style={{fontSize: "14px"}}>{t("meetingList.matching")}</span>,
            onClick: (evt, data: any) => {
              confirmAlert(
                confirmModalOptions({
                  title: t("meetingList.selectedAutoMatching"),
                  message: t("meetingList.matchesTheCategoryWithTheRightParticipant"),
                  click: () => choiceMatchingHandler(data, ""),
                })
              );
            },
          },
          {
            tooltip: "",
            icon: () => <span style={{fontSize: "14px"}}>{t("meetingList.cancelMatching")}</span>,
            onClick: (evt, data: any) => {
              confirmAlert(
                confirmModalOptions({
                  title: t("meetingList.cancelAllSelectedMatching"),
                  click: () => choiceMatchingCancelHandler(data),
                })
              );
            },
          },
          {
            tooltip: "",
            icon: () => <span style={{fontSize: "14px"}}>{t("meetingList.mailSending")}</span>,
            onClick: (evt, data: any) => {
              confirmAlert(
                confirmModalOptions({
                  title: t("meetingList.sendTheSelectedConcludedMail"),
                  click: () => matchingMailSendChoice(data),
                })
              );
            },
          },
        ]}
      />
      {meetingAlertModal && meetingAndSystemMailModal()}
      {systemMailModal && meetingAndSystemMailModal()}
      {/* {meetingAlertModal && (
        <>
          <ModalContainer>
            <ModalTitle>알림</ModalTitle>
            <ModalContent>{subEvent?.meetingAvailable === "N" ? MEETING_CONTRACT_ON_MESSAGE : MEETING_CONTRACT_OFF_MESSAGE}</ModalContent>
            {subEvent?.meetingAvailable === "N" ? <MeetingDirection /> : null}
            <PxButton backgroundcolor="purple" onClick={() => meetingDirectionCompleteBtn()}>
              <ButtonTypo>확인</ButtonTypo>
            </PxButton>
          </ModalContainer>
        </>
      )}

      {systemMailModal && (
        <ModalContainer>
          <ModalTitle>알림</ModalTitle>
        </ModalContainer>
      )} */}
    </>
  );
};

export default MeetingList;

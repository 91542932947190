import {IconButton} from "@material-ui/core";
import React, {Dispatch, SetStateAction, useEffect} from "react";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import EditIcon from "@material-ui/icons/EditOutlined";
import AddIcon from "@material-ui/icons/Add";
import {KeywordType} from "../../../../types/models/AutonoForm";

interface Props {
  item: KeywordType;
  setItems: Dispatch<SetStateAction<KeywordType[]>>;
  editOnClick: (key: string) => void;
}

const TextField: React.FC<Props> = props => {
  const {item, editOnClick, setItems} = props;

  const deleteOnClick = (key: string) => {
    const deleteItemRecursively = (menuItems: KeywordType[]): KeywordType[] => {
      return menuItems
        .filter(item => item.key !== key)
        .map(item => {
          if (item.children) {
            item.children = deleteItemRecursively(item.children);
          }
          return item;
        });
    };
    setItems(prevItems => deleteItemRecursively(prevItems));
  };

  return (
    <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%"}}>
      <span>{item.title}</span>
      <span>
        <IconButton
          onClick={e => {
            e.stopPropagation();
            editOnClick(item.key);
          }}
          style={{padding: "1px"}}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          onClick={e => {
            e.stopPropagation();
            deleteOnClick(item.key);
          }}
          style={{padding: "1px"}}
        >
          <DeleteIcon />
        </IconButton>
      </span>
    </div>
  );
};

export default TextField;

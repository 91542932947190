import {find, reject} from "lodash";
import React, {Children, createContext, Dispatch, ReactNode, SetStateAction, useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {setAlertAction} from "../../../../../../../actions/alerts";
import {updateRegisterFormAnswers} from "../../../../../../../actions/applicants";
import {updateApplicationAnswers} from "../../../../../../../actions/application";
import {getForms} from "../../../../../../../actions/forms";
import {removeLoadingAction, setLoadingAction} from "../../../../../../../actions/loading";
import {AppState} from "../../../../../../../store";
import {ApplicationFormAnswers} from "../../../../../../../types/models/Application";
import {FormData} from "../../../../../../../types/models/AutonoForm";
import {RegisterFormAnswer} from "../../../../../../../types/models/Registration";

interface RegisterInfoContextProps {
  children: ReactNode;
}

interface RegisterInfoContextType {
  formId?: number;
  modifyStatus: boolean;
  activeTab: number;
  contentForm: FormData | undefined;
  contentFormAnswers: RegisterFormAnswer[];
  modifiedFormAnswers: RegisterFormAnswer[];
  setContentFormAnswers: Dispatch<SetStateAction<RegisterFormAnswer[]>>;
  setModifyStatus: Dispatch<SetStateAction<boolean>>;
  setActiveTab: Dispatch<SetStateAction<number>>;
  modifyContentAnswers: (answers: RegisterFormAnswer, content: string) => void;
  modifyManyContentAnswers: (uuid: string, newApplicationManyFormAnswers: RegisterFormAnswer[]) => void;
  submit: (e: React.MouseEvent) => void;
}

export const RegisterInfoContext = createContext<RegisterInfoContextType>(null!);

const RegisterInfoProvider: React.FC<RegisterInfoContextProps> = ({children}) => {
  const dispatch = useDispatch();
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const forms = useSelector((state: AppState) => state.forms);
  const userDetail = useSelector((state: AppState) => state.registration.userDetail);
  const [modifyStatus, setModifyStatus] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [contentForm, setContentForm] = useState<FormData>();
  const [modifiedFormAnswers, setModifiedFormAnswers] = useState<RegisterFormAnswer[]>([]);
  const [contentFormAnswers, setContentFormAnswers] = useState<RegisterFormAnswer[]>([]);

  useEffect(() => {
    if (forms.content == undefined || forms.content == "") return;
    const contentForm: FormData = JSON.parse(forms.content);
    setContentForm(contentForm);
  }, [forms]);

  useEffect(() => {
    if (!subEvent?.registerForm) return;
    dispatch(getForms(subEvent.registerForm, Number(subEvent.id)));
  }, [subEvent]);

  useEffect(() => {
    if (!userDetail.registerFormAnswer) return;
    if (userDetail.registerFormAnswer.length == 0) return;
    setContentFormAnswers(userDetail.registerFormAnswer);
  }, [userDetail]);

  useEffect(() => {
    if (modifyStatus === true && contentFormAnswers.length != 0) {
      const clonedFormAnswers = [...contentFormAnswers].map(answer => {
        return {...answer};
      });
      setModifiedFormAnswers(clonedFormAnswers);
    }
  }, [modifyStatus, contentFormAnswers]);

  useEffect(() => {
    if (!userDetail.registerFormAnswer) return;
    setContentFormAnswers(userDetail.registerFormAnswer);
  }, [userDetail.registerFormAnswer]);

  useEffect(() => {
    console.log(`modifiedFormAnswer : `, modifiedFormAnswers);
  }, [modifiedFormAnswers]);

  const modifyContentAnswers = (registerAnswer: RegisterFormAnswer, content: string) => {
    const answer = find(modifiedFormAnswers, {id: registerAnswer.id});

    switch (registerAnswer.type) {
      case "single":
        const splitRadioValue = (content as string).split("[S]");
        if (answer !== undefined) {
          answer.answerUuid = splitRadioValue[0];
          answer.content = splitRadioValue[1];
        }
        break;

      default:
        if (answer !== undefined) {
          answer.content = content as string;
        }
        break;
    }
    setModifiedFormAnswers([...modifiedFormAnswers]);
  };

  const modifyManycontentAnswers = (
    uuid: string, // 질문의 고유id
    newApplicationManyFormAnswers: RegisterFormAnswer[] // 새로 추가될 복수선택 답변
  ) => {};

  const submitOnClick = async (e: React.MouseEvent) => {
    e.preventDefault();
    dispatch(setLoadingAction());

    const finalModifiedRegisterFormAnswers = reject(modifiedFormAnswers, {
      type: "many",
      answerUuid: undefined,
    });

    let validationCheck;
    let alert: any;

    contentForm?.formIds.forEach((formId, index) => {
      const formData = contentForm.formData[formId];
      if (formData.requireCheck === "Y") {
        if (formData.type === "many") {
          const result = find(finalModifiedRegisterFormAnswers, {
            uuid: formId,
            type: "many",
          });
          if (!result) {
            validationCheck = false;
            alert = {
              id: "formRequired",
              msg: "필수값을 입력(선택)해주세요.",
              alertType: "warning",
            };
          }
        } else {
          const result = find(finalModifiedRegisterFormAnswers, {
            uuid: formId,
          });
          if (result?.content === undefined || result?.content === "" || result?.content === null) {
            validationCheck = false;
            alert = {
              id: "formRequired",
              msg: "필수값을 입력(선택)해주세요.",
              alertType: "warning",
            };
            return;
          }
        }
      } else {
        const result = find(finalModifiedRegisterFormAnswers, {
          uuid: formId,
        });
        if (result?.content === undefined || result?.content === "" || result?.content === null) {
          // if (index === 0) {
          //   alert = {
          //     id: "formCompanyNameRequired",
          //     msg: "소속명을 입력해주세요.",
          //     alertType: "warning",
          //   };
          //   validationCheck = false;
          //   return;
          // } else if (index === 1) {
          //   alert = {
          //     id: "formCategoryRequired",
          //     msg: "카테고리를 선택해주세요.",
          //     alertType: "warning",
          //   };
          //   validationCheck = false;
          //   return;
          // } else if (index === 2) {
          //   alert = {
          //     id: "formKeywordRequired",
          //     msg: "키워드를 입력해주세요.",
          //     alertType: "warning",
          //   };
          //   validationCheck = false;
          //   return;
          // } else if (index === 3 && type === "buyer") {
          //   alert = {
          //     id: "formCategoryRequired",
          //     msg: "필수값을 입력(선택)해주세요.",
          //     alertType: "warning",
          //   };
          //   validationCheck = false;
          //   return;
          // }
        }
        validationCheck = true;
      }
    });

    if (validationCheck === false) {
      dispatch(removeLoadingAction());
      return dispatch(setAlertAction(alert));
    }

    const result: any = await dispatch(updateRegisterFormAnswers(finalModifiedRegisterFormAnswers));
    dispatch(removeLoadingAction());

    setModifyStatus(false);

    // 업데이트 성공 시 수정상태 변경 true -> false
    //   if (result) {
    //     setModifyStatus(false);
    //   }
  };

  return (
    <RegisterInfoContext.Provider
      value={{
        formId: forms.id,
        modifyStatus: modifyStatus,
        activeTab: activeTab,
        contentForm: contentForm,
        contentFormAnswers: contentFormAnswers,
        modifiedFormAnswers: modifiedFormAnswers,
        modifyContentAnswers: modifyContentAnswers,
        setContentFormAnswers: setContentFormAnswers,
        setModifyStatus: setModifyStatus,
        setActiveTab: setActiveTab,
        modifyManyContentAnswers: modifyManycontentAnswers,
        submit: submitOnClick,
      }}
    >
      {children}
    </RegisterInfoContext.Provider>
  );
};

export default RegisterInfoProvider;

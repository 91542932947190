import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import uuid from "uuid";
import ListAltIcon from "@material-ui/icons/ListAltOutlined";
import { Form, FormElement } from "../../../../types/models/Form";

export const defaultSgroup = [
  {
    title: "소속명",
    type: "short",
    explainable: true,
  },
  {
    title: "키워드",
    type: "keyword",
    explainable: true,
    keywordOption: [],
  }
]

export const defaultBgroup = [
  {
    title: "소속명",
    type: "short",
    explainable: true,
  },
  {
    title: "키워드",
    type: "keyword",
    explainable: true,
  },
]


// 수출 상담회

export const exportSgroup:FormElement[] = [
  {
    title: "소속명",
    type: "short",
  },
  {
    title: "키워드",
    type: "keyword",
    explain: "해당되는 키워드를 입력 하세요.",
    // keywordOption: ["진찰용기기", "의료정보시스템", "의료기기부품"],
    keywordOption: [
      {
        key: "sdfsdfsdfss",
        title: "진찰용기기",
      },
      {
        key: "sdfdss23",
        title: "의료정보시스템",
      },
      {
        key: "sdfshdjkfhsk",
        title: "의료기기부품"
      }
    ],
    explainable: true,
    requirable:false,
  },
  {
    title: "부스로고",
    type: "file",
    explain: "이미지 크기 160*160px, 종류: jpg, png",
    explainable: true,
    requirable: true
  },
  {
    title: "회사소개서",
    type: "file",
    explainable: true,
    requirable: true,
    explain: "파일크기 10Mb이하"
  },
  {
    title: "홈페이지",
    type: "url",
    explainable: true,
    requirable: true,
    explain: ""
  },
  {
    title: "개인정보 활용",
    type: "single",
    required: true,
    explainable: true,
    requirable: true,
    explain: "개인정보보호법 제 15조 법규에 의거하여 고객님의 개인정보 수집 및 활용에 대해 개인정보 수집 및 활용 동의를 받고 있습니다. 개인정보 제공자가 동의한 내용 외의 다른 목적으로 활용하지 않으며, 제공된 개인정보의 이용을 거부하고자 할 때에는 개인정보 관리책임자를 통해 열람, 정정 혹은 삭제를 요구할 수 있습니다.",
    content: ["동의합니다."]
  }
];

export const exportSgroupAdd = [
  {
    title: "",
    type: "product",
    required: false,
    explainable: false,
  }
]

export const exportBgroup:FormElement[] = [
  {
    title: "소속명",
    type: "short",
    explainable: true,
    requirable: false
  },
  {
    title: "키워드",
    type: "keyword",
    explainable: true,
    requirable: false,
    explain: "해당되는 키워드를 입력 하세요",
    keywordOption:["유통업체", "VARs", "소매업"]
  },
  {
    title: "부스로고",
    type: "file",
    explainable: true,
    requirable: true,
    explain: "이미지 크리 160*160px, 종류: jpg, png"
  },
  {
    title: "회사소개서",
    type: "file",
    explainable: true,
    requirable: true,
    explain: "파일크기 10mb 이하"
  },
  {
    title: "홈페이지",
    type: "url",
    explainable: true,
    requirable: true,
    explain: ""
  },
  {
    title: "개인정보 활용",
    type: "single",
    required: true,
    explainable: true,
    requirable: true,
    explain: "개인정보보호법 제 15조 법규에 의거하여 고객님의 개인정보 수집 및 활용에 대해 개인정보 수집 및 활용 동의를 받고 있습니다. 개인정보 제공자가 동의한 내용 외의 다른 목적으로 활용하지 않으며, 제공된 개인정보의 이용을 거부하고자 할 때에는 개인정보 관리책임자를 통해 열람, 정정 혹은 삭제를 요구할 수 있습니다.",
    content: ["동의합니다."]
  }
]

// IR 비즈 매칭

export const irSgroup:FormElement[] = [
  {
    title: "소속명",
    type: "short",
    explainable: false,
  },
  {
    title: "키워드",
    type: "keyword",
    explainable: true,
    requirable: false,
    explain: "해당되는 키워드를 입력 하세요.",
    keywordOption: [
      {
        key: "sub1",
        title: "앱",
      },
      {
        key: "sub2",
        title: "SW"
      },
      {
        key: "sub3",
        title: "샤회적기업"
      }
    ],
  },
  {
    title: "부스로고",
    type: "file",
    required: false,
    explainable: true,
    requirable: true,
    explain: "이미지 크기 160*160px, 종류: jpg, png",
  },
  {
    title: "IR 자료",
    type: "file",
    required: false,
    explainable: true,
    requirable: true,
    explain: "파일크기 10MB이하"
  },
  {
    title: "홈페이지",
    type: "url",
    required: false,
    explainable: true,
    requirable: true,
    explain: ""
  },
  {
    title: "개인정보 활용",
    type: "single",
    required: true,
    requirable:true,
    explainable: true,
    explain: "개인정보보호법 제 15조 법규에 의거하여 고객님의 개인정보 수집 및 활용에 대해 개인정보 수집 및 활용 동의를 받고 있습니다. 개인정보 제공자가 동의한 내용 외의 다른 목적으로 활용하지 않으며, 제공된 개인정보의 이용을 거부하고자 할 때에는 개인정보 관리책임자를 통해 열람, 정정 혹은 삭제를 요구할 수 있습니다.",
    content: ["동의합니다."]
  }
];

export const irBgroup:FormElement[] = [
  {
    title: "소속명",
    type: "short",
    explainable: false,
    requirable: false
  },
  {
    title: "키워드",
    type: "keyword",
    explainable: true,
    requirable:false,
    explain: "해당되는 키워드를 입력하세요",
    keywordOption: ["전기전자", "SW", "정보통신"]
  },
  {
    title: "카테고리",
    type: "single",
    explainable: true,
    requirable: false,
    explain: "해당되는 카테고리를 선택하세요",
    content:["기계소재", "정보통신", "바이오의료"]
  },
  {
    title: "부스로고",
    type: "file",
    required: false,
    explainable: true,
    requirable:true,
    explain: "이미지 크기 160*160px, 종류: jpg, png"
  },
  {
    title: "홈페이지",
    type: "url",
    required: false,
    requirable: true,
    explainable: true,
    explain: ""
  },
  {
    title: "개인정보 활용",
    type: "single",
    required: true,
    requirable: true,
    explainable: true,
    explain: "개인정보보호법 제 15조 법규에 의거하여 고객님의 개인정보 수집 및 활용에 대해 개인정보 수집 및 활용 동의를 받고 있습니다. 개인정보 제공자가 동의한 내용 외의 다른 목적으로 활용하지 않으며, 제공된 개인정보의 이용을 거부하고자 할 때에는 개인정보 관리책임자를 통해 열람, 정정 혹은 삭제를 요구할 수 있습니다.",
    content: ["동의합니다."]
  }
]

// 취업 박람회

export const jobSgroup:FormElement[] = [
  {
    title: "소속명",
    type: "short",
    required: false,
    explainable: true,
    requirable: false,
  },
  {
    title: "키워드",
    type: "keyword",
    explainable: true,
    requirable: false,
    explain: "해당되는 키워드를 입력하세요",
    keywordOption: ["영어","중국어","엑셀"],
  },
  {
    title: "이력서",
    type: "file",
    required: false,
    requirable: true,
    explainable: true,
    explain: "파일크기 10MB이하",
    content: [""]
  },
  {
    title: "포트폴리오",
    type: "file",
    required: false,
    explainable: true,
    requirable: true,
    explain: "파일크기 10MB이하",
    content: [""]
  },
  {
    title: "개인정보 활용",
    type: "single",
    required: true,
    explainable: true,
    requirable: true,
    explain: "개인정보보호법 제 15조 법규에 의거하여 고객님의 개인정보 수집 및 활용에 대해 개인정보 수집 및 활용 동의를 받고 있습니다. 개인정보 제공자가 동의한 내용 외의 다른 목적으로 활용하지 않으며, 제공된 개인정보의 이용을 거부하고자 할 때에는 개인정보 관리책임자를 통해 열람, 정정 혹은 삭제를 요구할 수 있습니다.",
    content: ["동의합니다."]
  }
];

export const jobBgroup:FormElement[] = [
  {
    title: "소속명",
    type: "short",
    explainable: false,
    requirable: true,
  },
  {
    title: "키워드",
    type: "keyword",
    explainable: true,
    requirable: false,
    explain: "해당되는 키워드를 입력하세요",
    keywordOption:["마케팅","영어","디자인"],
  },
  {
    title: "카테고리",
    type: "single",
    required: false,
    requirable: false,
    explainable: true,
    explain: "해당되는 카테고리를 선택하세요",
    content: ["스타트업","중견기업","대기업"]
  },
  {
    title: "부스로고",
    type: "file",
    required: false,
    explainable: true,
    requirable: true,
    explain: "이미지 크기 160*160px, 종류: jpg, png",
  },
  {
    title: "홈페이지",
    type: "url",
    required: false,
    explainable: true,
    requirable: true,
    explain: "",
  },
  {
    title: "개인정보 활용",
    type: "single",
    required: true,
    explainable: true,
    requirable: true,
    explain: "개인정보보호법 제 15조 법규에 의거하여 고객님의 개인정보 수집 및 활용에 대해 개인정보 수집 및 활용 동의를 받고 있습니다. 개인정보 제공자가 동의한 내용 외의 다른 목적으로 활용하지 않으며, 제공된 개인정보의 이용을 거부하고자 할 때에는 개인정보 관리책임자를 통해 열람, 정정 혹은 삭제를 요구할 수 있습니다.",
    content: ["동의합니다."]
  }
];

// 관광 비즈 매칭

export const tourSgroup:FormElement[] = [
  {
    title: "소속명",
    type: "short",
    explainable: false,
  },
  {
    title: "키워드",
    type: "keyword",
    explainable: true,
    explain: "해당되는 키워드를 입력하세요",
    keywordOption: ["미주","유럽","중국"]
  },
  {
    title: "부스로고",
    type: "file",
    required: false,
    explainable: true,
    requirable: true,
    explain: "이미지 크기 160*160px, 종류: jpg, png",
  },
  {
    title: "회사소개서",
    type: "file",
    required: false,
    requirable: true,
    explainable: true,
    explain: "파일크기 10MB이하",
  },
  {
    title: "홈페이지",
    type: "url",
    required: false,
    requirable: true,
    explainable: true,
    explain: "",
  }, 
  {
    title: "개인정보 활용",
    type: "single",
    requirable: true,
    required: true,
    explainable: true,
    explain: "개인정보보호법 제 15조 법규에 의거하여 고객님의 개인정보 수집 및 활용에 대해 개인정보 수집 및 활용 동의를 받고 있습니다. 개인정보 제공자가 동의한 내용 외의 다른 목적으로 활용하지 않으며, 제공된 개인정보의 이용을 거부하고자 할 때에는 개인정보 관리책임자를 통해 열람, 정정 혹은 삭제를 요구할 수 있습니다.",
    content: ["동의합니다."]
  }
]

export const tourBgroup:FormElement[] = [
  {
    title: "소속명",
    type: "short",
    explainable: false,
  },
  {
    title: "키워드",
    type: "keyword",
    explainable: true,
    explain: "해당되는 키워드를 입력하세요",
    keywordOption:["미주","유럽","중국"]
  },
  {
    title: "부스로고",
    type: "file",
    required: false,
    requirable: true,
    explainable: true,
    explain: "이미지 크기 160*160px, 종류: jpg, png",
  },
  {
    title: "홈페이지",
    type: "file",
    required: false,
    requirable: true,
    explainable: true,
    explain: "이미지 크기 160*160px, 종류: jpg, png",
  },
  {
    title: "개인정보 활용",
    type: "single",
    required: true,
    requirable: true,
    explainable: true,
    explain: "개인정보보호법 제 15조 법규에 의거하여 고객님의 개인정보 수집 및 활용에 대해 개인정보 수집 및 활용 동의를 받고 있습니다. 개인정보 제공자가 동의한 내용 외의 다른 목적으로 활용하지 않으며, 제공된 개인정보의 이용을 거부하고자 할 때에는 개인정보 관리책임자를 통해 열람, 정정 혹은 삭제를 요구할 수 있습니다.",
    content: ["동의합니다."]
  }
]

export const registrationData = [
  {
    title: "분류",
    type: "single",
    required: true,
    explainable: true,
    content: ["참가업체","참관객","VIP","PRESS","발표자","기타"]
  },
]





import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams, RouteComponentProps, matchPath, useLocation} from "react-router-dom";
import {AppState} from "../../../../store";
import {Column, MTableToolbar} from "material-table";

// actions
import {
  getApplications,
  changeApplicationState,
  updateApplicationsZoomLink,
  changeApplicationNote,
  getApplicationCountBySubEventId,
  attendeeTypeChange,
  updateApplicationNote,
  changeApplicationStateList,
} from "../../../../actions/application";
import {setProgressAction} from "../../../../actions/progresses";

// custom ui comp
import PxTable from "../../../../components/Table/PxTable";
import PxGridContainer from "../../../Grid/PxGridContainer";
import PxOutlinedFormControl from "../../../Forms/PxOutlinedFormControl";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import moment from "moment";
import Button from "@material-ui/core/Button";
import {confirmModalOptions} from "../../../../utils/confirmModalOptions";
import {confirmAlert} from "react-confirm-alert";
import PxSelectBox from "../../../SelectBox/PxSelectBox";
import {MenuItem} from "@material-ui/core";
import {categoryAndCheckboxSelectTextToLinkAndText} from "../../../../utils/utils";

import {makeStyles} from "@material-ui/core/styles";
import {setTableInfoLoadingAction} from "../../../../actions/tableInfo";
import OutlinedInput from "@material-ui/core/OutlinedInput/OutlinedInput";
import {useTranslation} from "react-i18next";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {removeLoadingAction, setLoadingAction} from "../../../../actions/loading";
import withStyles from "@material-ui/core/styles/withStyles";
import Switch from "@material-ui/core/Switch";
import {setAlertAction} from "../../../../actions/alerts";
import PxButton from "../../../Buttons/PxButton";
import ButtonTypo from "../../../Typhography/ButtonTypo";
import PxOutlinedTxtField from "../../../Inputs/PxOutlinedTxtField";
import {setPxDialogAction} from "../../../../actions/dialogs";
import WarningRemove from "../../../Dialogs/WaringRemove";
import Box from "@material-ui/core/Box";
import AddApplicationDialog from "./AddApplicationDialog";
import PxIconButton from "../../../Buttons/PxIconButton";
import LinkIcon from "@material-ui/icons/Link";
import CallMadeIcon from "@material-ui/icons/CallMade";
import PxCopyToCip from "../../../CopyToClip/PxCopyToClip";
import {table} from "console";

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    minWidth: 320,
  },
  tableTitle: {
    marginTop: 20,
    display: "flex",
    alignItems: "center",
  },
  gubun: {fontSize: 14, paddingRight: 10},
  outlinedFormControl: {minWidth: 120},
  stateChangeBtnText: {fontSize: "14px"},
  addAplcBtn: {
    float: "right",
    marginTop: 10,
  },
  addTagBtn: {
    marginLeft: 10,
  },
}));

interface RowType {
  applicationApplicantId: string;
  applicationApproval: string;
  applicationCategory: string;
  applicationId: string;
  applicationKeyword: string;
  applicationMethod: string;
  applicationName: string;
  applicationType: string;
  applicationCreatedDate: string;
  type: "seller" | "buyer";
  // zoomLinkCheck: boolean;
  // zoomLink?: string;
  note?: string;
  note1?: string;
  note2?: string;
  note3?: string;
  attendeeType: string;
  attendeeTypeCheck: boolean;
}

interface TableStateType {
  columns: Array<Column<RowType>>;
  data: RowType[];
}

// interface ActionDataType {
//   data: {subEventId: string; type: string};
//   page?: number;
//   pageSize?: number;
//   searchWord?: string;
// }

type ActionDataType = {subEventId: string; type: string; page?: number};

const PurpleSwitch = withStyles({
  switchBase: {
    "&$checked": {
      color: "rgb(81, 45, 168)",
    },
    "&$checked + $track": {
      backgroundColor: "rgb(81, 45, 168)",
    },
  },
  checked: {},
  track: {},
})(Switch);

const ApplicationList: React.FC<RouteComponentProps> = ({match}) => {
  const [t, i18n] = useTranslation("lang", {useSuspense: false});
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const param: {id: string} = useParams();
  const applications = useSelector((state: AppState) => state.applications.applications);
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const event = useSelector((state: AppState) => state.events.event);
  const previousRoute = useSelector((state: AppState) => state.routePath.previousPath);
  const [type, setType] = useState("");
  const [getActionParam, setGetActionParam] = useState<ActionDataType>({
    subEventId: param.id,
    type: type,
  });
  const [columnDefDone, setColumnDefDone] = useState<boolean>(false);
  const [adminAlertDialogOpen, setAdminAlertDialogOpen] = useState<boolean>(false);
  const [noteText, setnoteText] = useState<{
    applicationId: string;
    text: string;
  }>();
  const [addAplcDialogOpen, setAddAplcDialogOpen] = useState<boolean>(false);

  // 페이징 테스트
  const tableInfo = useSelector((state: AppState) => state.tableInfo);
  const [initPage, setInitPage] = useState<number>();

  useEffect(() => {
    const isApplicationDetail = matchPath(previousRoute, {path: "/subEvent/:id/application/:seconId"});

    isApplicationDetail !== null ? setInitPage(applications?.number) : setInitPage(0);
  }, [previousRoute, applications?.number]);

  useEffect(() => {
    if (tableInfo.name === location.pathname && tableInfo.type) {
      setType(tableInfo.type);
    }
  }, [tableInfo]);

  ///// end

  const approvalChangeActionsList: any = {
    cancel: {
      title: t(""),
      prevState: ["approval", "reject"],
    },
    approval: {
      title: t("common.approval"),
      prevState: ["waiting"],
    },
    reject: {
      title: t("common.reject"),
      prevState: ["waiting"],
    },
    delete: {
      title: t("common.delete"),
      prevState: ["waiting", "waiting", "reject", "approval"],
    },
  };

  useEffect(() => {
    const callGetApplications = async () => {
      setGetActionParam({subEventId: param.id, type: type});
      await dispatch(getApplications({subEventId: param.id, type}));
      dispatch(setTableInfoLoadingAction(false));

      if (!applications || applications.content.length <= 0) dispatch(setTableInfoLoadingAction(false));
    };

    if (!addAplcDialogOpen) {
      // callGetApplications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addAplcDialogOpen]);

  const [state, setState] = React.useState<TableStateType>({
    columns: [
      {
        title: t("applicationList.group"),
        field: "applicationType",
        cellStyle: {
          width: "150px",
          paddingRight: 0,
          maxWidth: 140,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
      {
        title: t("applicationList.corporateName"),
        field: "applicationName",
        cellStyle: {
          paddingRight: 0,
          width: "cal(100%-820px)",
          maxWidth: "500px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
      {
        title: t("applicationList.manager"),
        field: "applicationManager",
        cellStyle: {
          paddingRight: 0,
          width: "cal(100%-820px)",
          maxWidth: "500px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
      // {
      //   title: t("applicationList.registerType"),
      //   field: "applicationMethod",
      //   cellStyle: { width: "100px", paddingRight: 0 },
      // },
      {
        title: t("applicationList.registerDate"),
        field: "applicationCreatedDate",
        cellStyle: {width: "120px", paddingRight: 0},
      },
      {
        title: t("applicationList.status"),
        field: "applicationApproval",
        cellStyle: {width: "120px", paddingRight: 0},
      },
      {
        title: "Online",
        field: "attendeeType",
        cellStyle: {width: "50px", paddingRight: 0},
        render: rowData => {
          // if (rowData.type === "buyer") {
          return (
            <div style={{display: "flex", paddingRight: 20}}>
              <FormControlLabel
                control={
                  <PurpleSwitch
                    checked={rowData.attendeeTypeCheck}
                    onClick={async e => {
                      e.stopPropagation();
                      dispatch(setLoadingAction());
                      // rowData.attendeeTypeCheck = true;
                      dispatch(attendeeTypeChange(rowData.applicationId, rowData.attendeeType));

                      // await dispatch(updateApplicationsZoomLink([rowData.applicationId]));
                      dispatch(removeLoadingAction());
                    }}
                    name="zoomSwitch"
                  />
                }
                label=""
                style={{margin: 0}}
              />
              {/* {rowData.zoomLinkCheck && (
                  <>
                    <PxCopyToCip text={rowData.zoomLink!}>
                      <PxIconButton
                        onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                          event.stopPropagation();
                        }}
                      >
                        <LinkIcon />
                      </PxIconButton>
                    </PxCopyToCip>
                    <PxIconButton
                      onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        event.stopPropagation();
                        window.open(rowData.zoomLink, "_blank");
                      }}
                    >
                      <CallMadeIcon />
                    </PxIconButton>
                  </>
                )} */}
            </div>
          );
          // } else {
          //   return <></>;
          // }
        },
      },
      {
        title: t("applicationList.note"),
        field: "note",
        cellStyle: {
          width: "120px",
          textAlign: "inherit",
          paddingRight: 0,
        },
        render: rowData => (
          <PxOutlinedTxtField
            id={rowData.applicationId + `note`}
            inputpadding={5}
            defaultValue={rowData.note ?? ""}
            onClick={e => {
              e.stopPropagation();
            }}
            onBlur={() => {
              // note text update api call
            }}
            onKeyPress={e => {
              if (e.key === "Enter") {
                const inputElem: any = document.getElementById(rowData.applicationId + `note`);
                const noteContent = inputElem.value;
                const noteColumn = `note`;
                dispatch(updateApplicationNote(rowData.applicationId, noteContent, noteColumn));
                if (inputElem) {
                  setnoteText({
                    applicationId: rowData.applicationId,
                    text: inputElem.value,
                  });
                }
              }
            }}
          />
        ),
      },
      {
        title: t("applicationList.note"),
        field: "note1",
        cellStyle: {
          width: "120px",
          textAlign: "inherit",
          paddingRight: 0,
        },
        render: rowData => (
          <PxOutlinedTxtField
            id={rowData.applicationId + `note1`}
            inputpadding={5}
            defaultValue={rowData.note1 ?? ""}
            onClick={e => {
              e.stopPropagation();
            }}
            onBlur={() => {
              // note text update api call
            }}
            onKeyPress={e => {
              if (e.key === "Enter") {
                const inputElem: any = document.getElementById(rowData.applicationId + `note1`);
                const noteContent = inputElem.value;
                const noteColumn = `note1`;

                dispatch(updateApplicationNote(rowData.applicationId, noteContent, noteColumn));
                // if (inputElem) {
                //   setnoteText({
                //     applicationId: rowData.applicationId,
                //     text: inputElem.value,
                //   });
                // }
              }
            }}
          />
        ),
      },
      {
        title: "",
        field: "",
        cellStyle: {
          width: "250px",
          textAlign: "inherit",
          paddingRight: 0,
        },
        render: rowData => ["approval", "reject", "cancel", "delete"].map(action => statusChangeButtonForm(rowData.applicationApproval, rowData, action)),
      },
    ],
    data: [],
  });

  // 참가자 노트 작성 시
  useEffect(() => {
    if (noteText) {
      const newData = [...state.data];

      const selectedDataIndex = newData.findIndex(row => row.applicationId === noteText.applicationId);

      if (selectedDataIndex !== -1) {
        newData[selectedDataIndex].note = noteText.text;
      }

      const updateApplicationNote = async () => {
        const result: any = await dispatch(
          changeApplicationNote({
            id: newData[selectedDataIndex].applicationId,
            note: noteText.text,
          })
        );
        if (result) {
          setState(prevState => ({
            ...prevState,
            data: newData,
          }));

          // 다음 노트 input 있을 시 focus 이동
          if (newData[selectedDataIndex + 1]) {
            const nextInputElem: any = document.getElementById(newData[selectedDataIndex + 1].applicationId);
            if (nextInputElem) {
              nextInputElem.focus();
            }
          }

          dispatch(
            setAlertAction({
              id: newData[selectedDataIndex].applicationName,
              msg: `${newData[selectedDataIndex].applicationName} ${t("applicationList.saved")}`,
              alertType: "success",
            })
          );
        }
      };

      updateApplicationNote();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noteText]);

  // 회의(presentation)경우 테이블의 컬럼명과 테이블 컬럼 구성이 바뀜
  // 한번 테이블이 렌더링되면 column을 바꿔도 테이블이 다시 렌더링 되지않기 때문에 columnDef state로 테이블의 컬럼이 결정되면 테이블이 렌더링되도록 함
  useEffect(() => {
    if (subEvent && subEvent.category === "presentation") {
      const columns = [...state.columns];
      columns[1].title = t("common.name");
      columns.splice(-3, 1); // zoom링크를 끄고 켜는 기능이 없음
      setState(prevState => ({
        ...prevState,
        columns: columns,
      }));
      setColumnDefDone(true);
    } else if (subEvent?.category === "consulting") {
      setColumnDefDone(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subEvent]);

  const TagColumn = (fieldName: string, rowData: RowType) => {};

  useEffect(() => {
    let tableData: any[] = [];
    let note2Col: any[] = [];
    let note3Col: any[] = [];
    if (applications && Array.isArray(applications.content) && applications.content.length > 0) {
      dispatch(setTableInfoLoadingAction(true));

      applications.content.forEach(application => {
        if (application.note2 !== ("" || null)) note2Col.push(application.note2);
        else if (application.note3 !== ("" || null)) note3Col.push(application.note3);

        const rowData = {
          applicationApplicantId: application.applicantId,
          applicationApproval: application.approval,
          applicationCategory: application.category ? categoryAndCheckboxSelectTextToLinkAndText(application.category!, false) : "",
          applicationId: application.id,
          applicationKeyword: application.keyword,
          // applicationMethod:
          //   application.method === "pre"
          //     ? t("applicationList.preRegister")
          //     : t("applicationList.onsiteRegister"),
          applicationName: application.name,
          applicationManager: application.applicants ? application.applicants[0].businessCardId?.name : "",
          applicationType: application.type === "seller" ? subEvent?.sgroupName : subEvent?.bgroupName,
          applicationCreatedDate: moment(application.createdDate).format("YYYY-MM-DD"),
          note: application.note,
          note1: application.note1,
          note2: application.note2,
          note3: application.note3,
          type: application.type,
          attendeeType: application.attendeeType,
          attendeeTypeCheck: application.attendeeType == "online" ? true : false,
          // zoomLinkCheck: application.zoomLinkId ?? false,
          // zoomLink: application.zoomLinkId?.link,
        };

        tableData.push(rowData);
      });
      dispatch(setTableInfoLoadingAction(false));
    }

    if (note3Col.length > 0) {
      state.columns.splice(
        state.columns.length - 1,
        1,
        {
          title: t("applicationList.note"),
          field: "note2",
          cellStyle: {
            width: "120px",
            textAlign: "inherit",
            paddingRight: 0,
          },
          render: rowData => (
            <PxOutlinedTxtField
              id={rowData.applicationId + `note2`}
              inputpadding={5}
              defaultValue={rowData.note2 ?? ""}
              onClick={e => {
                e.stopPropagation();
              }}
              onBlur={() => {
                // note text update api call
              }}
              onKeyPress={e => {
                if (e.key === "Enter") {
                  const inputElem: any = document.getElementById(rowData.applicationId + `note2`);
                  const noteContent = inputElem.value;
                  const noteColumn = `note2`;
                  dispatch(updateApplicationNote(rowData.applicationId, noteContent, noteColumn));
                  // if (inputElem) {
                  //   setnoteText({
                  //     applicationId: rowData.applicationId,
                  //     text: inputElem.value,
                  //   });
                  // }
                }
              }}
            />
          ),
        },
        {
          title: t("applicationList.note"),
          field: "note3",
          cellStyle: {
            width: "120px",
            textAlign: "inherit",
            paddingRight: 0,
          },
          render: rowData => (
            <PxOutlinedTxtField
              id={rowData.applicationId + `note3`}
              inputpadding={5}
              defaultValue={rowData.note3 ?? ""}
              onClick={e => {
                e.stopPropagation();
              }}
              onBlur={() => {
                // note text update api call
              }}
              onKeyPress={e => {
                if (e.key === "Enter") {
                  const inputElem: any = document.getElementById(rowData.applicationId + `note3`);
                  const noteContent = inputElem.value;
                  const noteColumn = `note3`;

                  dispatch(updateApplicationNote(rowData.applicationId, noteContent, noteColumn));

                  // if (inputElem) {
                  //   setnoteText({
                  //     applicationId: rowData.applicationId,
                  //     text: inputElem.value,
                  //   });
                  // }
                }
              }}
            />
          ),
        },
        {
          title: "",
          field: "",
          cellStyle: {
            width: "250px",
            textAlign: "inherit",
            paddingRight: 0,
          },
          render: rowData => ["approval", "reject", "cancel", "delete"].map(action => statusChangeButtonForm(rowData.applicationApproval, rowData, action)),
        }
      );
    } else if (note2Col.length > 0) {
      state.columns.splice(state.columns.length - 1, 0, {
        title: t("applicationList.note"),
        field: "note2",
        cellStyle: {
          width: "120px",
          textAlign: "inherit",
          paddingRight: 0,
        },
        render: rowData => (
          <PxOutlinedTxtField
            id={rowData.applicationId}
            inputpadding={5}
            defaultValue={rowData.note2 ?? ""}
            onClick={e => {
              e.stopPropagation();
            }}
            onBlur={() => {
              // note text update api call
            }}
            onKeyPress={e => {
              if (e.key === "Enter") {
                const inputElem: any = document.getElementById(rowData.applicationId + `note2`);
                const noteContent = inputElem.value;
                const noteColumn = `note2`;
                dispatch(updateApplicationNote(rowData.applicationId, noteContent, noteColumn));
              }
            }}
          />
        ),
      });
    }
    setState(prevCreateDate => {
      return {...prevCreateDate, columns: state.columns, data: tableData};
    });
  }, [applications, subEvent]);

  // 상태 변경
  const approvalChangeFunc = async (changeData: any, approval: string, message?: string) => {
    dispatch(setProgressAction({open: "true"}));

    let adminDialogIsRequire = false; // 참가자 10명이상 경우, dialog open require check

    if (subEvent === undefined) {
      return;
    }

    if (approval === "approval" && subEvent?.category === "consulting") {
      const approvedApplicationsCount: any = await dispatch(getApplicationCountBySubEventId(subEvent.id!));

      const approvedApplicationsSize = approvedApplicationsCount + changeData.length; // 승인된 참가자 + 승인할 참가자

      if (approvedApplicationsSize > 10 && subEvent?.payment !== "Y") {
        adminDialogIsRequire = true;
        const willApprovedApplicationSize = 10 - approvedApplicationsCount;
        // 승인해줄 application이 승인될 application의 수 보다 많을경우만 splice
        if (changeData.length - willApprovedApplicationSize >= 0) {
          changeData.splice(0, changeData.length - willApprovedApplicationSize);
        }
      }
    }

    let successNum = 0;

    const requestParam = changeData.map((actionTargetInData: any) => {
      return {
        id: actionTargetInData.applicationId,
        subEventId: param.id,
        prevApproval: actionTargetInData.applicationApproval,
        action: approval,
        message: message!,
      };
    });

    await dispatch(changeApplicationStateList(requestParam));

    // await Promise.all(
    //   changeData.map(async (actionTargetInData: any) => {
    //     const approvalChangeParam = {
    //       id: actionTargetInData.applicationId,
    //       subEventId: param.id,
    //       prevApproval: actionTargetInData.applicationApproval,
    //       action: approval,
    //       message: message!,
    //     };

    //     const result: any = await dispatch(changeApplicationState(approvalChangeParam));

    //     if (result) {
    //       successNum = successNum + 1;
    //     }
    //   })
    // );

    await dispatch(setProgressAction({open: "false"}));
    // 참가자 10명이상 경우, dialog open
    if (adminDialogIsRequire) {
      setAdminAlertDialogOpen(true);
    }
    dispatch(getApplications({subEventId: param.id, type}, tableInfo.pageNum));
  };

  // 선택 상태 변경 여러명 (승인, 거절, 취소, 삭제)
  const approvalChangeMultiFunc = (action: string, data: any) => {
    let title: string = approvalChangeActionsList[action].title;
    let prevState: string[] = approvalChangeActionsList[action].prevState;

    const actionTargetList: string[] = data.filter((inData: any) => prevState.includes(inData.applicationApproval));

    if (actionTargetList.length === 0) {
      confirmAlert(confirmModalOptions({title: `${title}가능한 대상이 없습니다`})); // 번역 noPossible
      return;
    }

    handleClickModalOpen(title, action, actionTargetList);
  };

  // 상태값 변경 버튼
  const statusChangeButtonForm = (status: string, rowData: any, action: string) => {
    let title = "";
    let buttonBackgroundColor = "#512da8";
    let buttonTypoColor = "white";

    if (status === "waiting" && action === "approval") {
      title = t("applicationList.approval");
      buttonBackgroundColor = "#512da8";
    } else if (status === "waiting" && action === "reject") {
      title = t("applicationList.reject");
      buttonBackgroundColor = "#e4d7f7";
      buttonTypoColor = "black";
    } else if ((status === "approval" || status === "reject") && action === "cancel") {
      buttonBackgroundColor = "#f1f2f5";
      buttonTypoColor = "black";
      title = t("applicationList.cancel");
    } else if (action === "delete") {
      title = t("applicationList.delete");
      buttonBackgroundColor = "white";
      buttonTypoColor = "black";
    }

    return (
      <>
        {title !== "" && (
          <>
            <Button
              onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                event.stopPropagation();
                handleClickModalOpen(title, action, [rowData]);
              }}
              style={{
                backgroundColor: buttonBackgroundColor,
                fontWeight: 500,
                color: buttonTypoColor,
                margin: "3px",
                border: action === "delete" ? "1px solid silver" : "none",
              }}
              size="small"
            >
              {title}
            </Button>
          </>
        )}
      </>
    );
  };

  const [approvalModalData, setApprovalModalData] = React.useState({
    open: false,
    title: "",
    action: "",
    rowData: [],
    message: "",
  });

  const handleClickModalOpen = useCallback((title: string, action: string, rowData: any) => {
    setApprovalModalData({
      open: true,
      title: title,
      action: action,
      rowData: rowData,
      message: "",
    });
  }, []);

  const handleModalClose = useCallback(() => {
    setApprovalModalData({
      open: false,
      title: "",
      action: "",
      rowData: [],
      message: "",
    });
  }, []);

  const handleModalMessageChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setApprovalModalData({
        ...approvalModalData,
        message: e.target.value,
      });
    },
    [approvalModalData]
  );

  // 선택된 buyer 전부 zoom link 를 넣어주거나 없애줌
  const onClickSelectedApplicationSetZoomLink = async (evt: any, data: any) => {
    dispatch(setLoadingAction());
    const applicationIds = data.filter((application: RowType) => application.type === "buyer").map((application: RowType) => application.applicationId);
    await dispatch(updateApplicationsZoomLink(applicationIds));
    dispatch(removeLoadingAction());
  };

  const onCloseAdminAlertDialog = () => {
    setAdminAlertDialogOpen(false);
  };

  const onChangeSearchParam = useCallback(
    (
      e: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
      }>
    ) => {
      setType(e.target.value as string);
      setGetActionParam({
        subEventId: getActionParam.subEventId,
        type: e.target.value as string,
      });
    },
    []
  );

  return (
    <>
      {/* 참가자 가져오기 dialog */}
      <AddApplicationDialog open={addAplcDialogOpen} setOpen={setAddAplcDialogOpen} />
      {/* 참가자 가져오기 버튼 */}
      <div className={classes.addAplcBtn}>
        <PxButton
          backgroundcolor="purple"
          onClick={() => {
            setAddAplcDialogOpen(true);
          }}
        >
          <ButtonTypo>참가자 가져오기</ButtonTypo>
        </PxButton>
        <PxButton
          style={{marginLeft: "7px"}}
          backgroundcolor="purple"
          onClick={() => {
            let tableData: any[] = [];

            applications?.content.forEach(application => {
              const rowData = {
                applicationApplicantId: application.applicantId,
                applicationApproval: application.approval,
                applicationCategory: application.category ? categoryAndCheckboxSelectTextToLinkAndText(application.category!, false) : "",
                applicationId: application.id,
                applicationKeyword: application.keyword,
                // applicationMethod:
                //   application.method === "pre"
                //     ? t("applicationList.preRegister")
                //     : t("applicationList.onsiteRegister"),
                applicationName: application.name,
                applicationManager: application.applicants ? application.applicants[0].businessCardId?.name : "",
                applicationType: application.type === "seller" ? subEvent?.sgroupName : subEvent?.bgroupName,
                applicationCreatedDate: moment(application.createdDate).format("YYYY-MM-DD"),
                note: application.note,
                note1: application.note1,
                note2: application.note2,
                note3: application.note3,
                type: application.type,
                attendeeType: application.attendeeType,
                attendeeTypeCheck: application.attendeeType == "online" ? true : false,
                // zoomLinkCheck: application.zoomLinkId ?? false,
                // zoomLink: application.zoomLinkId?.link,
              };
              tableData.push(rowData);
            });

            const currentTagColumns = state.columns.filter(item => item.title == "태그");

            if (currentTagColumns.length == 4) {
              dispatch(
                setAlertAction({
                  id: "tagColumnAddErr",
                  msg: `태그는 최대 4개까지 추가 가능합니다.`,
                  alertType: "warning",
                })
              );
              return;
            }

            // 컬럼이 4개가 존재하면 더이상 생성 안되는 기능

            state.columns.splice(7, 0, {
              title: t("applicationList.note"),
              field: `note${currentTagColumns.length}`,
              cellStyle: {
                width: "120px",
                textAlign: "inherit",
                paddingRight: 0,
              },
              render: rowData => (
                <PxOutlinedTxtField
                  id={rowData.applicationId + `note${currentTagColumns.length}`}
                  inputpadding={5}
                  defaultValue={() => {
                    if (currentTagColumns.length == 1) {
                      return rowData.note1;
                    } else if (currentTagColumns.length == 2) {
                      return rowData.note2;
                    } else if (currentTagColumns.length == 3) {
                      return rowData.note3;
                    }
                  }}
                  onClick={e => {
                    e.stopPropagation();
                  }}
                  onBlur={() => {
                    // note text update api call
                  }}
                  onKeyPress={e => {
                    if (e.key === "Enter") {
                      const inputElem: any = document.getElementById(rowData.applicationId + `note${currentTagColumns.length}`);
                      const applicationId = rowData.applicationId;
                      const noteContent = inputElem.value;
                      const noteColumn = `note${currentTagColumns.length}`;

                      dispatch(updateApplicationNote(applicationId, noteContent, noteColumn));
                    }
                  }}
                />
              ),
            });
            setState(prevCreateDate => {
              return {...prevCreateDate, columns: state.columns, data: tableData};
            });
          }}
        >
          <ButtonTypo>태그 추가</ButtonTypo>
        </PxButton>
      </div>
      {
        applications && subEvent && columnDefDone && (
          <>
            <PxTable<RowType>
              components={{
                Toolbar: props => {
                  return (
                    <PxGridContainer direction="column">
                      {/* <TitleTypo style={{ paddingLeft: 10 }}>title</TitleTypo> */}
                      <MTableToolbar {...props} />
                    </PxGridContainer>
                  );
                },
              }}
              title={
                <div className={classes.tableTitle}>
                  <span className={classes.gubun}>{t("applicationList.group")}</span>
                  <PxOutlinedFormControl className={classes.outlinedFormControl}>
                    <PxSelectBox
                      value={type}
                      onChange={e => {
                        onChangeSearchParam(e);
                      }}
                      displayEmpty
                      input={<OutlinedInput margin="dense" />}
                    >
                      <MenuItem value={""}>{t("applicationList.all")}</MenuItem>
                      <MenuItem value={"seller"}>{subEvent?.sgroupName}</MenuItem>
                      <MenuItem value={"buyer"}>{subEvent?.bgroupName}</MenuItem>
                    </PxSelectBox>
                  </PxOutlinedFormControl>
                  <Box width={10} />
                </div>
              }
              getAction={getApplications}
              totalPage={applications?.totalPages}
              totalElements={applications?.totalElements}
              actionData={getActionParam}
              columns={state.columns}
              currentPage={matchPath(previousRoute, {path: "/subEvent/:id/application/:seconId"}) !== null ? applications.number : 0}
              data={state.data}
              onRowClick={(evt, selectedRow) => {
                history.push(`${match.url}/${selectedRow?.applicationId}`);
              }}
              // material-table 속성: https://material-table.com/#/docs/features/search
              options={{
                toolbar: true,
                actionsColumnIndex: -1,
                showTitle: true,
                defaultExpanded: false,
                search: true,
                selection: true,
                searchFieldAlignment: "right",
                exportButton: false, // csv 다운
              }}
              actions={[
                {
                  tooltip: "",
                  icon: () => <span className={classes.stateChangeBtnText}>Zoom</span>,
                  onClick: onClickSelectedApplicationSetZoomLink,
                },
                {
                  tooltip: "",
                  icon: () => <span className={classes.stateChangeBtnText}>{t("applicationList.approval")}</span>,
                  onClick: (evt, data: any) => approvalChangeMultiFunc("approval", data),
                },
                {
                  tooltip: "",
                  icon: () => <span className={classes.stateChangeBtnText}>{t("applicationList.reject")}</span>,
                  onClick: (evt, data: any) => approvalChangeMultiFunc("reject", data),
                },
                {
                  tooltip: "",
                  icon: () => <span className={classes.stateChangeBtnText}>{t("applicationList.cancel")}</span>,
                  onClick: (evt, data: any) => approvalChangeMultiFunc("cancel", data),
                },
                {
                  tooltip: "",
                  icon: () => <span className={classes.stateChangeBtnText}>{t("applicationList.delete")}</span>,
                  onClick: (evt, data: any) => approvalChangeMultiFunc("delete", data),
                },
              ]}
            />
          </>
        )
        // )
      }
      <Dialog open={approvalModalData.open} onClose={handleModalClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
          {approvalModalData.rowData.length > 0 && i18n.language === "ko"
            ? `${approvalModalData.title}가능한 대상이 ${approvalModalData.rowData.length}명이 있습니다 ${approvalModalData.title}하시겠습니까?`
            : approvalModalData.rowData.length &&
              `there is ${approvalModalData.rowData.length} that can be ${translate(approvalModalData.title)[0]},would you like to ${translate(approvalModalData.title)[1]}?`}
          {/* 번역 */}
        </DialogTitle>
        <DialogContent>
          {approvalModalData.rowData.length > 0 &&
            approvalModalData.rowData.map((actionTargetindata: any, index: number) => (
              <div key={`state-change-info-${index}`} style={{display: "flex", justifyContent: "space-between"}}>
                <div>{`${index + 1}. ${actionTargetindata.applicationName}`}</div>
                <div>{`${t("application")}: ${actionTargetindata.applicationCreatedDate}`}</div>
              </div>
            ))}
          {approvalModalData.action === "reject" && (
            <>
              <TextField
                autoFocus
                margin="dense"
                label={t("applicationList.resonForRejection")}
                placeholder={t("typeTheResonForRejection")}
                type="text"
                value={approvalModalData.message}
                onChange={handleModalMessageChange}
                fullWidth
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose} color="primary">
            {t("common.close")}
          </Button>
          <Button
            onClick={async () => {
              handleModalClose();
              subEvent?.mainEvent && approvalModalData.action === "delete"
                ? dispatch(
                    setPxDialogAction({
                      open: true,
                      children: (
                        <WarningRemove
                          title={t("applicationList.deleteTheSelectedParticipant")}
                          warningMsg={t("applicationList.dataCannotBeRecovered")}
                          text={subEvent.mainEvent.name}
                          onClick={async () => await approvalChangeFunc(approvalModalData.rowData, approvalModalData.action, approvalModalData.message)}
                          submitText={t("applicationList.iUnderstoodAndDelete")}
                        />
                      ),
                      footer: <></>,
                    })
                  )
                : await approvalChangeFunc(approvalModalData.rowData, approvalModalData.action, approvalModalData.message);
            }}
            color="primary"
          >
            {t("common.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
      {/* payment = Y : 승인됨 , W: 유료전환 신청중, N: 무료 */}
      <Dialog open={adminAlertDialogOpen} onClose={onCloseAdminAlertDialog}>
        {/* <DialogTitle className={classes.dialogTitle}>관리자 문의</DialogTitle> */}
        <DialogContent>{t("applicationList.pleaseContactTheAdmin")} </DialogContent>
        <DialogActions>
          <PxButton backgroundcolor="purple" onClick={onCloseAdminAlertDialog}>
            <ButtonTypo>{t("common.done")}</ButtonTypo>
          </PxButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ApplicationList;

function translate(title: string) {
  title = title.toLowerCase();
  const transTitle = title === "approval" ? ["approved", "approve"] : title === "cancel" ? ["cancelled", "cancel"] : title === "delete" ? ["deleted", "delete"] : ["rejected", "reject"];

  return transTitle;
}

import React, {useEffect, useState, useMemo, useCallback} from "react";
import uuid from "uuid";
import {useParams} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import axios, {AxiosResponse} from "axios";

// types
import {Form, FormElement, historyList} from "../../../../types/models/Form";
import {EventInfo} from "../../../../types/models/Event";

// 자율양식 form component
import AutoForm from "../../autonomous/AutoForm";
import {AppState} from "../../../../store";
import {getForms, getHistoryForm, updateForm} from "../../../../actions/forms";
import useFormContext from "../../../../components/Provider/FormProvider/useFormContext";
import FixNotice from "../../../../components/SubEvent/InsideMenu/Notice/FixNotice";
import PxOutlinedFormControl from "../../../../components/Forms/PxOutlinedFormControl";
import {useTranslation} from "react-i18next";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import PxSelectBox from "../../../../components/SelectBox/PxSelectBox";
import {exportSgroup, exportBgroup, irSgroup, irBgroup, jobSgroup, jobBgroup, tourSgroup, tourBgroup} from "../template/templateData";

const SellerPartiForm = () => {
  const [t] = useTranslation("lang", {useSuspense: false});
  const params = useParams<{id: string}>(); // subEvent id
  const {initialLoad, setInitialLoad} = useFormContext();
  const dispatch = useDispatch();
  const {subEvent} = useSelector((state: AppState) => state.subEvents);
  const forms = useSelector((state: AppState) => state.forms);
  const {history} = useSelector((state: AppState) => state.forms);
  const userId = useSelector((state: AppState) => state.users.id);
  const [defaultData, setDefaultData] = useState<Form>();
  const [selectValue, setSelectValue] = useState<string | undefined>();
  const [historyValue, setHistoryValue] = useState<historyList[]>();
  const [formInitial, setFormInitial] = useState<boolean>(false);

  const extractTextPattern = /(<([^>]+)>)/gi;

  const data = [
    {
      title: t("registeringForm.typeCorporatreName"),
      type: "short",
      explainable: true,
    },
    {
      title: t("registeringForm.selectCategory"),
      type: "category",
      explainable: true,
    },
    {
      title: t("registeringForm.typeKeywords"),
      type: "keyword",
      explainable: true,
      keywordOption: [],
    },
  ];

  let templateData: FormElement[] = data;

  // 이미 등록한 양식이 있으면 db에서 불러옴
  useEffect(() => {
    if (subEvent?.sellerPartiForm !== undefined && subEvent.sellerPartiForm !== 0 && initialLoad) {
      dispatch(getForms(subEvent.sellerPartiForm, Number(subEvent.id)));
      setInitialLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subEvent]);

  useEffect(() => {
    if (userId !== undefined) {
      dispatch(getHistoryForm(Number(userId)));
    }
  }, [userId]);

  // db에서 불러온 양식을 가공하여 자율양식 form으로 만들어줌
  useEffect(() => {
    if (forms.content !== "" && forms.content !== undefined) {
      const data: Form = {
        id: forms.id,
        type: forms.type,
        formData: JSON.parse(forms.content!),
        content: "",
        subEventId: parseInt(params.id),
      };
      setDefaultData({
        ...data,
        formData: JSON.parse(forms.content!),
        additionalFormData: JSON.parse(forms.additional!),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forms]);

  const historyFormSelect = async (event: React.ChangeEvent<{name: string | undefined; value: unknown}>) => {
    const formId = (event.target.value as string).split("|")[0];
    setSelectValue(event.target.value as string);
    if (event.target.value == "default") {
      setFormInitial(true);
    } else {
      dispatch(updateForm(Number(formId)));
      setInitialLoad(false);
    }
  };

  const makeDefaultFormData = useCallback(() => {
    if (formInitial) {
      setFormInitial(false);
      setDefaultData(undefined);
    }
    const map: Form = {
      type: "SP",
      formData: {formData: {}, formIds: []},
      content: "",
      subEventId: parseInt(params.id),
    };

    switch (forms.templateType) {
      case "default":
        templateData = data;
        break;
      case "export":
        templateData = exportSgroup;
        break;
      case "ir":
        templateData = irSgroup;
        break;
      case "job":
        templateData = jobSgroup;
        break;
      case "tour":
        templateData = tourSgroup;
        break;
    }

    const updateFormData = templateData.map(v => {
      const formId = `form-${uuid.v4()}`;
      let formIds = [formId];
      map.formData!.formIds.push(formId);

      let formData = {
        [formId]: {
          id: formId,
          explain: "",
          index: 0,
        },
      };

      if (v.content) {
        const answerData = v.content?.map((item, index) => {
          const answerId = `form-${uuid.v4()}`;
          index == 0 ? (formIds = [answerId]) : formIds.push(answerId);

          return {
            [answerId]: {
              id: answerId,
              explain: item,
              index: index,
            },
          };
        });

        formData = Object.assign({}, ...answerData);
      }

      return {
        [formId]: {
          id: formId,
          index: 0,
          type: v.type,
          selectable: v.title == "카테고리" || v.title == "키워드" ? false : true,
          optionable: true,
          title: v.title,
          explain: v.explain,
          secretCheck: "N",
          explainCheck: v.explainable ? "Y" : "N",
          requireCheck: v.required ? "Y" : "N",
          explainable: v.explainable,
          secretable: true,
          requirable: v.requirable,
          selectContent: {
            formData,
            formIds: formIds,
            // formData: {
            //   // [formId]: {
            //   //   id: formId,
            //   //   explain: "",
            //   //   index: 0,
            //   // },
            // },
            // formIds: [formId],
          },
          content: "",
          keywordOptions: v.keywordOption ? v.keywordOption : [],
        },
      };
    });
    Object.assign(map.formData!.formData, ...updateFormData);
    return map;
  }, [forms]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialData = useMemo(() => makeDefaultFormData(), [params.id, formInitial]);

  return (
    <>
      <FixNotice />

      {/* {history && (
        <>
          <InputLabel id="demo-simple-select-outlined-label">지난 이벤트 양식 불러오기</InputLabel>
          <PxOutlinedFormControl fullWidth>
            <PxSelectBox
              name="category"
              // label={t("common.status")}
              value={selectValue}
              onChange={(e: any) => historyFormSelect(e)}
              displayEmpty
            >
              <MenuItem value="default">기본 양식</MenuItem>
              {history.map((item, key) => {
                return (
                  <MenuItem key={key} value={`${item.formId}|${item.eventTitle}`}>
                    {item.eventTitle.replace(extractTextPattern, "")}
                  </MenuItem>
                );
              })}
            </PxSelectBox>
          </PxOutlinedFormControl>
        </>
      )} */}
      {defaultData !== undefined && <AutoForm initialFormData={defaultData} />}
      {defaultData === undefined && <AutoForm initialFormData={initialData} />}
    </>
  );
};

export default SellerPartiForm;

import {Chip} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import React from "react";
import {useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import PxOutlinedTxtField from "../../../../components/Inputs/PxOutlinedTxtField";
import {AppState} from "../../../../store";
import {KeywordType} from "../../../../types/models/AutonoForm";
import Category from "./Category";

const CategoryView: React.FC = props => {
  const location = useLocation();
  const forms = useSelector((state: AppState) => state.forms);

  return (
    <>
      {location.pathname.includes("application") ? (
        <>
          <Category colunmId="columnId-2" selectContent={true} initialData={forms.keywords} />
        </>
      ) : (
        // <></>
        <>
          {forms.keywords && (
            <Autocomplete<KeywordType, true, false, false>
              id="keywordOptions"
              options={forms.keywords}
              multiple
              autoHighlight
              //   onInputChange={(e, value) => bounceHandler(value)}
              // defaultValue={keywordList}
              //   value={keywordValue}
              //   filterOptions={(option, params) => {
              //       const filtered = filter(resultOption as string[], params);
              //       return filtered;
              //   }}
              //   onChange={(e, value, reason) => keywordOptionsOnChange(e, value, reason)}
              renderTags={
                (value: KeywordType[], getTagProps) => {
                  return value.map((option: KeywordType, index: number) => <Chip key={index} variant="outlined" label={option.title} {...getTagProps({index})} />);
                }
                //   value.map((option: string, index: number) => {
                //       return <Chip variant="outlined" label={option} {...getTagProps({ index })} />;
                //   })
              }
              renderInput={params => (
                <PxOutlinedTxtField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    style: {padding: 0},
                  }}
                  fullWidth
                  placeholder={"옵션으로 사용할 키워드를 입력해주세요."}
                  fontsize={14}
                />
              )}
            />
          )}
        </>
      )}
    </>
  );
};

export default CategoryView;

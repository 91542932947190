import React, {useEffect} from "react";
import styled from "styled-components";
import {Chip, Grid, TypographyProps} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import SubHeadingTypo from "../../../../Typhography/SubHeadingTypo";
import {MailRecipient} from "../../../../../types/models/Mail";
import {useSelector} from "react-redux";
import {AppState} from "../../../../../store";

type Props = {
  recipients: Array<MailRecipient>;
};

const SubTitle: React.FC<TypographyProps> = styled((props: TypographyProps) => {
  const {...rest} = props;
  return <SubHeadingTypo {...rest} />;
})`
  margin: 32px 0 8px 0;
  font-weight: bold;
`;

const MailRecipients: React.FC<Props> = ({recipients}) => {
  const mailTargets = useSelector((state: AppState) => state.mails.mailTargets);
  const [t] = useTranslation("lang", {useSuspense: false});

  const isAllSelected = mailTargets?.length === recipients.length;

  return (
    <>
      <SubTitle>{t("mailAdd.recipient")}</SubTitle>
      <Grid
        container
        direction="row"
        style={{
          padding: "10px",
          minHeight: "60px",
          backgroundColor: "rgba(0, 0, 0, 0.04)",
        }}
      >
        {isAllSelected ? (
          // mailTargets와 recipients 길이가 같을 때 '전체' Chip 렌더링
          <Chip key="email-all" label={"전체"} color="primary" variant="outlined" style={{margin: "3px 10px 3px 0"}} />
        ) : recipients != undefined && recipients.length > 0 ? (
          // mailTargets와 recipients 길이가 다를 때 각 recipient 별로 Chip 렌더링
          recipients.map((targetId: any, idx) => <Chip key={`email-${idx}`} label={targetId.email} color="primary" variant="outlined" style={{margin: "3px 10px 3px 0"}} />)
        ) : (
          // recipients가 없을 때 안내 문구 렌더링
          <div style={{alignSelf: "center", color: "#888"}}>{t("mailAdd.selectItFromTheList")}</div>
        )}
      </Grid>
    </>
  );
};

export default MailRecipients;

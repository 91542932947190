import {Table} from "@material-ui/core";
import React, {Dispatch, SetStateAction, useEffect, useMemo, useState} from "react";
import {MailRecipient} from "../../../../../types/models/Mail";
import MailUserRow, {RowData} from "./MailUserRow";

type TableData = {
  data: RowData[];
  recipients: MailRecipient[];
  setRecipients: Dispatch<SetStateAction<MailRecipient[]>>;
};

const MailUserTable: React.FC<TableData> = ({data, recipients, setRecipients}) => {
  const [checkedRows, setCheckedRows] = useState<RowData[]>([]);
  const [allChecked, setAllChecked] = useState<boolean>(false);

  const handleToggle = (row: RowData) => {
    setCheckedRows(prev => {
      const isRowSelected = prev.some(selectedRow => selectedRow.applicationId === row.applicationId);
      return isRowSelected ? prev.filter(item => item.applicationId !== row.applicationId) : [...prev, row];
    });
  };

  const handleAllCheck = () => {
    if (allChecked) {
      setCheckedRows([]);
    } else {
      setCheckedRows(data);
    }
    setAllChecked(!allChecked);
  };

  useEffect(() => {
    const changedRecipients = checkedRows.map(row => {
      const recipient: MailRecipient = {
        email: row.email,
        company: row.company,
        name: row.name,
        recipientId: row.applicationId,
      };
      return recipient;
    });

    setRecipients(changedRecipients);
    if (data.length > 0) {
      setAllChecked(checkedRows.length === data.length);
    }
  }, [checkedRows, setRecipients]);

  const rows = useMemo(() => {
    return data.map((row, idx) => <MailUserRow key={idx} row={row} isChecked={checkedRows.some(checkedRow => checkedRow.applicationId === row.applicationId)} onToggle={() => handleToggle(row)} />);
  }, [data, checkedRows]);

  return (
    <table style={{tableLayout: "fixed", width: "100%", borderCollapse: "collapse", maxHeight: "450px", overflowY: "auto"}}>
      <thead style={{position: "sticky", borderBottom: "1px solid #ddd"}}>
        <tr>
          <th style={{width: "50px"}}>
            <input type="checkbox" checked={allChecked} onChange={handleAllCheck} />
          </th>
          <th style={{width: "50px"}}>구분</th>
          <th style={{width: "200px"}}>이메일</th>
          <th style={{width: "200px"}}>소속</th>
          <th style={{width: "200px"}}>이름</th>
        </tr>
      </thead>
      <tbody style={{overflowY: "auto", maxHeight: "400px"}}>{rows}</tbody>
    </table>
  );
};

export default MailUserTable;

import React, {useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import {useLocation} from "react-router-dom";
import axios from "axios";

// types
import {KeywordType, DraggableItemComp} from "../../../types/models/AutonoForm";

// custom Component
import AutonomousForm from "./AutonomousForm";
import PxSelectFormContent from "./PxSelectFormContent";

// material comps
import Paper from "@material-ui/core/Paper";
import PxGridContainer from "../../../components/Grid/PxGridContainer";
import MenuItem from "@material-ui/core/MenuItem";
import PxSelectBox from "../../../components/SelectBox/PxSelectBox";
import PxGridItem from "../../../components/Grid/PxGridItem";
import PxIconButton from "../../../components/Buttons/PxIconButton";
import PxInputBase from "../../../components/Inputs/PxInputBase";
import PxButton from "../../../components/Buttons/PxButton";
import ButtonTypo from "../../../components/Typhography/ButtonTypo";
import Divider from "@material-ui/core/Divider";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

// material icons
import MenuIcon from "@material-ui/icons/Menu";
import DeleteIcon from "@material-ui/icons/Delete";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import AddIcon from "@material-ui/icons/Add";
import AdjustIcon from "@material-ui/icons/Adjust";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ShortTextIcon from "@material-ui/icons/ShortText";
import NotesIcon from "@material-ui/icons/Notes";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import LinkIcon from "@material-ui/icons/Link";
import StarIcon from "@material-ui/icons/Star";
import {useTranslation} from "react-i18next";
import Autocomplete, {createFilterOptions} from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import PxOutlinedTxtField from "../../../components/Inputs/PxOutlinedTxtField";
import {FilterOptionsState} from "@material-ui/lab";
import _ from "lodash";
import {useSelector} from "react-redux";
import {AppState} from "../../../store";

import InBoxIcon from "@material-ui/icons/Inbox";
import Category from "./category/Category";

const StyledFormLabelControl = styled(FormControlLabel)`
  & .MuiTypography-body1 {
    font-size: 13px;
  }
`;

const ProductContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProductProperty = styled.div`
  padding: 2px 0;
`;

// 전체 쟈율양식 폼
const PxAutonomousForm: React.FC<DraggableItemComp> = props => {
  const [t] = useTranslation("lang", {useSuspense: false});
  const {elevation, id, index, draghandle, setSelected, formCopy, formDelete, selectContentFormAdd, selectContentFormDelete, formData, initialData, setInitialData} = props;
  const location = useLocation();
  const [currentKeyword, setCurrentKeyword] = useState<string>();
  const [resultOption, setResultOption] = useState<string[]>([]);
  const [keywordValue, setKeywordvalue] = useState<string[]>();
  const [resCount, setResCount] = useState<number>(0);
  const userId = useSelector((state: AppState) => state.users.id);
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const filter = createFilterOptions<string>();

  // 키워드 값 설정
  useEffect(() => {
    if (formData?.keywordOptions) {
      console.log(`keywordOptions`);
      setKeywordvalue([]);
      // setKeywordvalue([...new Set(formData.keywordOptions)]);
    }
  }, [formData?.keywordOptions]);

  // 자율양식 타입 셀렉트박스 onChange
  const handleOnChange = (event: React.ChangeEvent<{value: unknown; name?: string | undefined}>) => {
    event.persist();
    if (formData !== undefined)
      setInitialData(prevState => {
        // const formData = prevState.formData;
        Object.assign(prevState.formData[id], {
          [event.target.name as string]: event.target.value as string,
        });
        return {...prevState};
      });
  };

  const checkBoxOnChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const name = event.target.name as string;
    const value = checked ? "Y" : "N";
    if (formData !== undefined)
      setInitialData(prevState => {
        const formData = prevState.formData;
        Object.assign(formData[id], {
          [name]: value,
        });
        return {...prevState, formData};
      });
  };

  const keywordOptionsOnChange = (event: React.ChangeEvent<{}>, value: string[], reason: string) => {
    if (formData) {
      setInitialData(prevState => {
        const formData = prevState.formData;
        Object.assign(formData[id], {
          keywordOptions: value,
        });
        return {...prevState, formData};
      });
    }
  };

  const bounceHandler = _.debounce(changeValue => keywordSearch(changeValue), 100);

  const keywordSearch = async (word: string) => {
    setCurrentKeyword(word);
    if (word !== currentKeyword && word.length > 0) {
      const res = await axios.get<string[]>("/api/keyword/getKeyword", {params: {word: word}});
      setResCount(res.data.length);
      if (res.data.length == 0) setResultOption([word]);
      else setResultOption(res.data);
    } else {
      setResultOption([]);
    }
  };

  return (
    <Paper
      elevation={elevation}
      className="mb-1"
      onClick={() => {
        setSelected(id);
      }}
    >
      <PxGridContainer>
        {/* 자율양식 헤더 */}
        {elevation === 10 && formData?.selectable !== false && (
          <PxGridContainer direction="row" justify="space-between" alignItems="center" style={{backgroundColor: "#eeeeee"}}>
            <PxGridItem className={"pl-2"} {...draghandle}>
              <MenuIcon />
            </PxGridItem>
            {/* 자율양식 셀렉트 */}
            <PxGridItem md={9} xs={8}>
              <PxSelectBox value={formData!.type} onChange={handleOnChange} displayEmpty name="type" fullWidth style={{backgroundColor: "white", paddingLeft: 10}}>
                <MenuItem value={"single"}>
                  <PxGridContainer direction="row">
                    <AdjustIcon fontSize="small" />
                    <ButtonTypo className="pl-2">{t("registeringForm.singleSelect")}</ButtonTypo>
                  </PxGridContainer>
                </MenuItem>
                <MenuItem value={"many"}>
                  <PxGridContainer direction="row">
                    <CheckBoxIcon fontSize="small" />
                    <ButtonTypo className="pl-2">{t("registeringForm.multipleSelect")}</ButtonTypo>
                  </PxGridContainer>
                </MenuItem>
                <MenuItem value={"short"}>
                  <PxGridContainer direction="row">
                    <ShortTextIcon fontSize="small" />
                    <ButtonTypo className="pl-2">{t("registeringForm.shortTextType")}</ButtonTypo>
                  </PxGridContainer>
                </MenuItem>
                <MenuItem value={"long"}>
                  <PxGridContainer direction="row">
                    <NotesIcon fontSize="small" />
                    <ButtonTypo className="pl-2">{t("registeringForm.longTextType")}</ButtonTypo>
                  </PxGridContainer>
                </MenuItem>
                {(location.pathname.includes("counselForm") || location.pathname.includes("surveyForm")) && (
                  <MenuItem value={"star"}>
                    <PxGridContainer direction="row">
                      <StarIcon fontSize="small" />
                      <ButtonTypo className="pl-2">{t("registeringForm.starRating")}</ButtonTypo>
                    </PxGridContainer>
                  </MenuItem>
                )}
                {/* 상담일지 or 사후설문에서는 파일첨부 양식 없음 */}
                <MenuItem value={"file"}>
                  <PxGridContainer direction="row">
                    <AttachFileIcon fontSize="small" />
                    <ButtonTypo className="pl-2">{t("registeringForm.fileAttach")}</ButtonTypo>
                  </PxGridContainer>
                </MenuItem>
                <MenuItem value={"url"}>
                  <PxGridContainer direction="row">
                    <LinkIcon fontSize="small" />
                    <ButtonTypo className="pl-2">{t("registeringForm.urlLink")}</ButtonTypo>
                  </PxGridContainer>
                </MenuItem>
                {!location.pathname.includes("counselForm") && !location.pathname.includes("surveyForm") && !location.pathname.includes("sellerPartiForm") && (
                  <MenuItem value={"product"}>
                    <PxGridContainer direction="row">
                      <InBoxIcon fontSize="small" />
                      <ButtonTypo className="pl-2">{"제품정보"}</ButtonTypo>
                    </PxGridContainer>
                  </MenuItem>
                )}
              </PxSelectBox>
            </PxGridItem>
            {/* 폼 복사하기, 삭제하기 버튼 */}

            <PxGridItem>
              {subEvent && subEvent.mainEvent!.approval !== "Y" && (
                <PxIconButton
                  onClick={() => {
                    formDelete !== undefined && formDelete(id);
                  }}
                >
                  <DeleteIcon />
                </PxIconButton>
              )}

              <PxIconButton
                onClick={() => {
                  formCopy !== undefined && formCopy(id, index);
                }}
              >
                <FileCopyIcon />
              </PxIconButton>
            </PxGridItem>
          </PxGridContainer>
        )}
        {/* 자율양식 본문 */}
        <PxGridContainer className="py-3 pl-3" direction="column">
          {formData?.type !== "product" && (
            <PxGridItem style={{fontWeight: "bold"}}>
              {t("registeringForm.question")}:{" "}
              <PxInputBase
                name="title"
                value={formData!.title}
                placeholder={t("common.title")}
                fontsize={15}
                readOnly={formData?.selectable === false ? true : false}
                fontweight="bold"
                autoComplete="off"
                onChange={handleOnChange}
              />
              {/* {formData !== undefined && formData.title !== undefined && (            
              <>{freeFormQuestionText(formData!.title!, formData!.type!)}</>
            )} */}
            </PxGridItem>
          )}
          {formData!.explainCheck === "Y" && (
            <PxGridItem>
              <PxInputBase
                name="explain"
                multiline
                value={formData!.explain}
                fullWidth
                placeholder={t("registeringForm.writeExplanation")}
                fontsize={14}
                fontweight="bold"
                autoComplete="off"
                onChange={handleOnChange}
              />
            </PxGridItem>
          )}
          {/**
           *    category 항목 제외
           */}
          {/* 답변 항목 만들기 */}
          {(formData!.type === "many" || formData!.type === "single") && (
            <AutonomousForm
              colunmId="columnId-2"
              selectContent={true}
              formId={id} // 전체 폼의 id
              initialData={formData?.selectContent}
              setInitialData={setInitialData}
              draggableComp={<PxSelectFormContent selectContentFormDelete={selectContentFormDelete} setInitialData={setInitialData} initialData={initialData} />}
            />
          )}

          {formData?.type === "product" && (
            <PxGridItem style={{fontWeight: "bold"}}>
              <ProductContainer>
                <ProductProperty>{`질문 : 제품명`}</ProductProperty>
                <ProductProperty>{"질문 : 제품 이미지"}</ProductProperty>
                <ProductProperty>{"질문 : 제품 설명"}</ProductProperty>
                <ProductProperty>{"질문 : 제품 링크"}</ProductProperty>
              </ProductContainer>
            </PxGridItem>
          )}

          {/* 키워드 옵션 */}
          {keywordValue && formData?.type === "keyword" && (
            <>
              {location.pathname.includes("sellerPartiForm") ? (
                <>
                  <Category colunmId="columnId-2" selectContent={true} formId={id} initialData={formData?.keywordOptions} setFormData={setInitialData} />
                </>
              ) : (
                <Autocomplete
                  id="keywordOptions"
                  options={keywordValue}
                  freeSolo
                  multiple
                  autoHighlight
                  onInputChange={(e, value) => bounceHandler(value)}
                  // defaultValue={keywordList}
                  value={keywordValue}
                  filterOptions={(option, params) => {
                    const filtered = filter(resultOption as string[], params);
                    return filtered;
                  }}
                  onChange={(e, value, reason) => keywordOptionsOnChange(e, value, reason)}
                  renderTags={(value: string[], getTagProps) =>
                    value.map((option: string, index: number) => {
                      return <Chip variant="outlined" label={option} {...getTagProps({index})} />;
                    })
                  }
                  renderInput={params => (
                    <PxOutlinedTxtField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        style: {padding: 0},
                      }}
                      fullWidth
                      placeholder={"옵션으로 사용할 키워드를 입력해주세요."}
                      fontsize={14}
                    />
                  )}
                />
              )}
            </>
          )}
        </PxGridContainer>
        {/* 추가하기 버튼 */}
        {(formData!.type === "many" || formData!.type === "single") && (
          <PxGridContainer className="ml-5 mb-3">
            <PxButton
              backgroundcolor="purple"
              startIcon={<AddIcon style={{color: "white"}} />}
              onClick={() => {
                selectContentFormAdd !== undefined && selectContentFormAdd(id);
              }}
            >
              <ButtonTypo>{t("registeringForm.add")}</ButtonTypo>
            </PxButton>
          </PxGridContainer>
        )}

        <Divider light style={{width: "100%"}} />

        {/* 체크박스 */}
        {elevation === 10 && formData?.optionable !== false && (
          <PxGridContainer className="pt-2 pl-3">
            <FormGroup row>
              {formData?.explainable === true && (
                <StyledFormLabelControl
                  control={<Checkbox color="primary" checked={formData!.explainCheck === "Y" ? true : false} onChange={checkBoxOnChange} name="explainCheck" />}
                  label={t("registeringForm.explain")}
                />
              )}
              {formData?.secretable === true && !location.pathname.includes("CounselForm") && !location.pathname.includes("SurveyForm") && (
                <StyledFormLabelControl
                  control={<Checkbox color="primary" checked={formData!.secretCheck === "Y" ? true : false} onChange={checkBoxOnChange} name="secretCheck" />}
                  label={t("registeringForm.privateInfo")}
                />
              )}
              {formData?.requirable === true && (
                <StyledFormLabelControl
                  control={<Checkbox color="primary" checked={formData!.requireCheck === "Y" ? true : false} onChange={checkBoxOnChange} name="requireCheck" />}
                  label={t("registeringForm.required")}
                />
              )}
            </FormGroup>
          </PxGridContainer>
        )}
      </PxGridContainer>
    </Paper>
  );
};

export default PxAutonomousForm;

import React, {useEffect, useState} from "react";
import uuid from "uuid";
import {useParams} from "react-router-dom";

// 자율양식 form component
import AutoForm from "../../autonomous/AutoForm";
import {Form} from "../../../../types/models/Form";
import {AppState} from "../../../../store";
import {useDispatch, useSelector} from "react-redux";
import useFormContext from "../../../../components/Provider/FormProvider/useFormContext";
import {getForms} from "../../../../actions/forms";

const AttPartiForm = () => {
  const {initialLoad, setInitialLoad} = useFormContext();
  const params = useParams<{id: string}>(); // subEvent id

  const dispatch = useDispatch();
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const forms = useSelector((state: AppState) => state.forms);

  const [defaultData, setDefaultData] = useState<Form>();

  // 이미 등록한 양식이 있으면 db에서 불러옴
  useEffect(() => {
    if (subEvent?.sellerPartiForm !== undefined && subEvent.sellerPartiForm !== 0 && initialLoad) {
      dispatch(getForms(subEvent.sellerPartiForm, Number(subEvent.id)));
      setInitialLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subEvent]);

  // db에서 불러온 양식을 가공하여 자율양식 form으로 만들어줌
  useEffect(() => {
    if (forms.content !== "" && forms.content !== undefined) {
      const data: Form = {
        id: forms.id,
        type: forms.type,
        content: "",
        subEventId: parseInt(params.id),
      };
      setDefaultData({
        ...data,
        formData: JSON.parse(forms.content!),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forms]);

  const makeDefaultFormData = () => {
    const map: Form = {
      type: "AP",
      formData: {formData: {}, formIds: []},
      content: "",
      subEventId: parseInt(params.id),
    };
    // const data = [
    //   { title: "이름", type: "short" },
    //   { title: "휴대번호", type: "short" },
    //   { title: "이메일", type: "short" },
    // ].map((v) => {
    //   const formId = `form-${uuid.v4()}`;
    //   map.formData!.formIds.push(formId);
    //   return {
    //     [formId]: {
    //       id: formId,
    //       index: 0,
    //       type: v.type,
    //       selectable: false,
    //       optionable: false,
    //       title: v.title,
    //       explain: "",
    //       secretCheck: "N",
    //       explainCheck: "N",
    //       requireCheck: "Y",
    //       selectContent: {
    //         formData: {
    //           [formId]: {
    //             id: formId,
    //             explain: "",
    //             index: 0,
    //           },
    //         },
    //         formIds: [formId],
    //       },
    //       content: "",
    //     },
    //   };
    // });
    const data = [{}];
    Object.assign(map.formData!.formData, ...data);
    return map;
  };
  const initialData = makeDefaultFormData();

  return (
    <>
      {defaultData !== undefined && <AutoForm initialFormData={defaultData} />}
      {defaultData === undefined && <AutoForm initialFormData={initialData} />}
    </>
  );
};

export default AttPartiForm;

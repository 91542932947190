import {Grid, MenuItem, OutlinedInput, TypographyProps} from "@material-ui/core";
import React, {Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import SubHeadingTypo from "../../../../Typhography/SubHeadingTypo";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import PxTable from "../../../../Table/PxTable";
import PxOutlinedFormControl from "../../../../Forms/PxOutlinedFormControl";
import PxSelectBox from "../../../../SelectBox/PxSelectBox";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../../../store";
import {Column} from "material-table";
import {setTableInfoLoadingAction} from "../../../../../actions/tableInfo";
import {getSubEvent} from "../../../../../actions/subEvents";
import {useParams} from "react-router-dom";
import {MailInfo, MailRecipient, MailTargetInfo} from "../../../../../types/models/Mail";
import SubEvent, {SubEventInfo} from "../../../../../types/models/SubEvent";
import MailUserTable from "../MailUserTable/MailUserTable";

type Props = {
  recipients: Array<MailRecipient>;
  setRecipients: Dispatch<SetStateAction<Array<MailRecipient>>>;
};

interface RowType {
  applicationId: string;
  approval: string;
  category: string;
  company: string;
  email: string;
  keyword: string;
  method: string;
  name: string;
  type: string;
}

const SubTitle: React.FC<TypographyProps> = styled((props: TypographyProps) => {
  const {...rest} = props;
  return <SubHeadingTypo {...rest} />;
})`
  margin: 32px 0 8px 0;
  font-weight: bold;
`;

const FolderBtn = styled.span`
  float: right;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.6);
`;
const Folder = styled.div<{open: boolean}>`
  overflow: hidden;
  display: ${props => (props.open ? "none" : "block")};
  /* transition: all 0.3s; */
`;

const MailUserList: React.FC<Props> = ({recipients, setRecipients}) => {
  const dispatch = useDispatch();
  const param: {id: string} = useParams();
  const [t] = useTranslation("lang", {useSuspense: false});
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const mailTargets = useSelector((state: AppState) => state.mails.mailTargets);
  const [folder, setFolder] = useState<boolean>(false);
  const [selectParam, setSelectParam] = useState<string>("");
  const [tableData, setTableData] = useState<Array<RowType>>([]);
  const columns: Array<Column<RowType>> = [
    {
      title: t("mailAdd.group"),
      field: "type",
      cellStyle: {width: "100px", paddingRight: 0},
    },
    {
      title: t("common.email"),
      field: "email",
      cellStyle: {width: "100px", paddingRight: 0},
    },
    {
      title: t("common.company"),
      field: "company",
      cellStyle: {width: "100px", paddingRight: 0},
    },
    {
      title: t("common.name"),
      field: "name",
      cellStyle: {width: "100px", paddingRight: 0},
    },
    {
      title: t("mailAdd.category"),
      field: "category",
      cellStyle: {width: "100px", paddingRight: 0},
    },
    {
      title: t("mailAdd.keyword"),
      field: "keyword",
      cellStyle: {width: "100px", paddingRight: 0},
    },
    {
      title: t("mailAdd.approvalStatus"),
      field: "approval",
      cellStyle: {width: "100px", paddingRight: 0},
    },
  ];

  useEffect(() => {
    if (Array.isArray(mailTargets) && mailTargets.length > 0 && subEvent) {
      mappingMailTargets(mailTargets, subEvent);
    } else {
      if (!subEvent) {
        callSubEvent();
      }
    }
  }, [mailTargets, subEvent]);

  const mappingMailTargets = (mailTargets: MailTargetInfo[], subEventParam: SubEventInfo) => {
    let tableInputData: RowType[] = [];
    mailTargets.map(mailTarget => {
      const rowData: RowType = {
        approval: mailTarget.approval || "",
        category: mailTarget.category || "",
        company: mailTarget.company || "",
        email: mailTarget.email || "",
        keyword: mailTarget.keyword || "",
        method: mailTarget.method || "",
        name: mailTarget.name || "",
        type: mailTarget.type === "seller" ? subEventParam.sgroupName : subEventParam.bgroupName,
        applicationId: mailTarget.applicationId || "",
      };
      tableInputData.push(rowData);
    });
    setTableData(tableInputData);
    dispatch(setTableInfoLoadingAction(false));
  };

  const callSubEvent = useCallback(async () => {
    dispatch(setTableInfoLoadingAction(true));
    await dispatch(getSubEvent(parseInt(param.id)));
    // dispatch(setTableInfoLoadingAction(false));
  }, [dispatch, param.id]);

  const selectTargets = (selectedUser: RowType[]) => {
    const targetList: MailRecipient[] = selectedUser.map((data: RowType) => {
      const recipient: MailRecipient = {
        email: data.email,
        company: data.company,
        name: data.name,
        recipientId: data.applicationId,
      };
      return recipient;
    });
    setRecipients(targetList);
  };

  const memoizedTable = useMemo(() => {
    return (
      <PxTable<RowType>
        title={
          <div
            style={{
              width: "100%",
              margin: 5,
            }}
          >
            <PxOutlinedFormControl>
              <PxSelectBox
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.2) 0px 1px 5px 0px, rgba(0, 0, 0, 0.12) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px",
                }}
                value={selectParam}
                onChange={e => setSelectParam(e.target.value as string)}
                displayEmpty
                input={<OutlinedInput margin="dense" />}
              >
                <MenuItem value={""}>{t("mailAdd.all")}</MenuItem>
                <MenuItem value={"seller"}>{subEvent ? subEvent.sgroupName : "SELLER"}</MenuItem>
                <MenuItem value={"buyer"}>{subEvent ? subEvent.bgroupName : "BUYER"}</MenuItem>
              </PxSelectBox>
            </PxOutlinedFormControl>
          </div>
        }
        columns={columns}
        data={tableData}
        // material-table 속성: https://material-table.com/#/docs/features/search
        options={{
          toolbar: true,
          actionsColumnIndex: -1,
          paging: false,
          headerStyle: {position: "sticky", top: 0},
          maxBodyHeight: "300px",
          // pageSize: 10,
          showTitle: true,
          defaultExpanded: false,
          search: true,
          selection: true,
          searchFieldAlignment: "right",
          exportButton: false, // csv 다운
        }}
        onSelectionChange={rows => selectTargets(rows)}
      />
    );
  }, [folder, tableData]);

  return (
    <>
      <SubTitle>
        {t("mailAdd.list")}
        <FolderBtn onClick={() => setFolder(!folder)}>
          {folder ? (
            <>
              {t("mailAdd.open")} <ArrowDropDownIcon />
            </>
          ) : (
            <>
              {t("mailAdd.fold")} <ArrowDropUpIcon />
            </>
          )}
        </FolderBtn>
      </SubTitle>

      <Folder open={folder}>
        <Grid
          container
          direction="row"
          style={{
            border: "1px solid #ccc",
            padding: "10px",
            minHeight: "60px",
            maxHeight: "500px",
            overflowY: "auto",
          }}
        >
          <MailUserTable data={tableData} recipients={recipients} setRecipients={setRecipients} />
          {/* {memoizedTable} */}
          {/* <PxTable<RowType>
            title={
              <div
                style={{
                  width: "100%",

                  margin: 5,
                }}
              >
                <PxOutlinedFormControl>
                  <PxSelectBox
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.2) 0px 1px 5px 0px, rgba(0, 0, 0, 0.12) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px",
                    }}
                    value={selectParam}
                    onChange={e => setSelectParam(e.target.value as string)}
                    displayEmpty
                    input={<OutlinedInput margin="dense" />}
                  >
                    <MenuItem value={""}>{t("mailAdd.all")}</MenuItem>
                    <MenuItem value={"seller"}>{subEvent ? subEvent.sgroupName : "SELLER"}</MenuItem>
                    <MenuItem value={"buyer"}>{subEvent ? subEvent.bgroupName : "BUYER"}</MenuItem>
                  </PxSelectBox>
                </PxOutlinedFormControl>
              </div>
            }
            columns={columns}
            data={tableData}
            // material-table 속성: https://material-table.com/#/docs/features/search
            options={{
              toolbar: true,
              actionsColumnIndex: -1,
              paging: false,
              headerStyle: {position: "sticky", top: 0},
              maxBodyHeight: "300px",
              // pageSize: 10,
              showTitle: true,
              defaultExpanded: false,
              search: true,
              selection: true,
              searchFieldAlignment: "right",
              exportButton: false, // csv 다운
            }}
            onSelectionChange={rows => selectTargets(rows)}
          /> */}
        </Grid>
      </Folder>
    </>
  );
};

export default MailUserList;

import {Menu, Button} from "antd";
import React, {useEffect, useState} from "react";
import PxGridItem from "../../../../components/Grid/PxGridItem";
import {KeywordType, FormData} from "../../../../types/models/AutonoForm";
import "antd/dist/antd.css";
import AddIcon from "@material-ui/icons/Add";
import {IconButton} from "@material-ui/core";
import EditFiled from "./EditFiled";
import TextField from "./TextField";
import PxButton from "../../../../components/Buttons/PxButton";
import ButtonTypo from "../../../../components/Typhography/ButtonTypo";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../../store";
import {v4 as uuidv4} from "uuid";
import {setKeyword} from "../../../../actions/forms";

interface Props {
  colunmId: string; // droppable container column Id
  selectContent?: boolean; // 단수나 복수 콘텐츠 drag & drop 인지 구분
  formId?: string; // 단수나 복수 콘텐츠 정렬 시 initialData formData의 id값 필요
  initialData: KeywordType[] | undefined; // draggable items data
  setFormData?: React.Dispatch<React.SetStateAction<FormData>>; // draggable items setState
}

const Category: React.FC<Props> = props => {
  const {initialData, setFormData} = props;
  const dispatch = useDispatch();
  const keywords = useSelector((state: AppState) => state.forms.keywords);
  const [items, setItems] = useState<KeywordType[]>([]);
  const [selectedKey, setSelectedKey] = useState<string | null>("");
  const [newItemTitle, setNewItemTitle] = useState<string>("");
  const [editTarget, setEditTarget] = useState<string | null>(null);
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const [initialState, setInitialState] = useState<boolean>(false);

  useEffect(() => {
    if (initialData && initialData.length > 0) {
      setItems(initialData);
    }
  }, [initialData, initialState]);

  useEffect(() => {
    dispatch(setKeyword(items));
  }, [items, dispatch]);

  const initializeKey = (keywords: KeywordType[]): KeywordType[] => {
    return keywords.map(parent => {
      if (!parent.key) {
        parent.key = uuidv4();
      }
      if (parent.children) {
        initializeKey(parent.children);
      }
      return parent;
    });
  };

  const handleAddItem = (parentKey: string | null = null) => {
    if (parentKey == null) {
      setSelectedKey(parentKey);
      setEditTarget("");
    } else {
      setSelectedKey(parentKey);
      const updatedItems = [...items];
      const res: KeywordType | null = findItemByKey(updatedItems, parentKey!);
      if (res !== null && !res.children) {
        res.children = [];
        setItems([...updatedItems]);
      }
    }
  };

  const findItemByKey = (items: KeywordType[], key: string): KeywordType | null => {
    for (let item of items) {
      if (item.key === key) {
        return item;
      }
      if (item.children) {
        const found = findItemByKey(item.children, key);
        if (found) {
          return found;
        }
      }
    }
    return null;
  };

  const handleClick = (e: any) => {
    setSelectedKey(e.key);
  };

  const handleAddItemCancel = () => {
    setNewItemTitle("");
    setEditTarget(null);
  };

  const handleSubMenuToggle = (key: string) => {
    setOpenKeys(prevKeys => {
      if (prevKeys.includes(key)) {
        // 이미 열려 있으면 닫기
        return prevKeys.filter(openKey => openKey !== key);
      } else {
        // 열려 있지 않으면 열기
        return [...prevKeys, key];
      }
    });
  };

  const editOnClick = (key: string) => {
    setEditTarget(key);
  };

  const handleAddItemConfirm = () => {
    if (newItemTitle) {
      const newItem: KeywordType = {
        key: `${selectedKey}-${new Date().getTime()}`,
        title: newItemTitle,
      };

      if (selectedKey === null) {
        setItems(prevItems => [...prevItems, newItem]);
      } else if (editTarget == null) {
        const addToParent = (items: KeywordType[]): KeywordType[] => {
          return items.map(item => {
            if (item.key === selectedKey) {
              if (!item.children) {
                item.children = [];
              }
              item.children.push(newItem);
            } else if (item.children) {
              item.children = addToParent(item.children);
            }
            return item;
          });
        };
        setItems(addToParent(items));
      } else {
        const updateItemTitle = (items: KeywordType[]): KeywordType[] => {
          return items.map(item => {
            if (item.key === editTarget) {
              // 현재 선택된 항목의 타이틀을 업데이트
              return {...item, title: newItemTitle};
            } else if (item.children) {
              // 자식 항목에 대해 재귀적으로 처리
              item.children = updateItemTitle(item.children);
            }
            return item;
          });
        };
        setItems(updateItemTitle(items));
      }

      setNewItemTitle("");
      setSelectedKey("");
      setEditTarget(null);
    }
  };

  const renderMenuItems = (menuItems: KeywordType[], depth: number = 1) => {
    return menuItems.map(item => {
      if (item.children) {
        return (
          <Menu.SubMenu
            key={item.key}
            title={
              editTarget === item.key ? (
                <EditFiled newItemTitle={newItemTitle} setNewItemTitle={setNewItemTitle} handleAddItemConfirm={handleAddItemConfirm} handleAddItemCancel={handleAddItemCancel} />
              ) : (
                <TextField item={item} setItems={setItems} editOnClick={editOnClick} />
              )
            }
            style={{minWidth: "280px"}}
          >
            {renderMenuItems(item.children, depth + 1)}
            {selectedKey === item.key && (
              <EditFiled newItemTitle={newItemTitle} setNewItemTitle={setNewItemTitle} handleAddItemConfirm={handleAddItemConfirm} handleAddItemCancel={handleAddItemCancel} />
            )}
            <Menu.Item key={`${item.key}-add`} onClick={() => setSelectedKey(item.key)} style={{display: "flex", justifyContent: "center"}}>
              <PxButton
                backgroundcolor="purple"
                onClick={e => {
                  e.stopPropagation();
                  handleAddItem(item.key);
                }}
              >
                <ButtonTypo>키워드 추가</ButtonTypo>
              </PxButton>
            </Menu.Item>
          </Menu.SubMenu>
        );
      }
      return (
        <Menu.Item key={item.key} onClick={() => handleSubMenuToggle(item.key)} style={{display: "flex", justifyContent: "space-between", minWidth: "280px"}}>
          {editTarget === item.key ? (
            <EditFiled newItemTitle={newItemTitle} setNewItemTitle={setNewItemTitle} handleAddItemConfirm={handleAddItemConfirm} handleAddItemCancel={handleAddItemCancel} />
          ) : (
            <>
              <TextField item={item} setItems={setItems} editOnClick={editOnClick} />
              {depth < 3 && (
                <IconButton
                  style={{padding: "1px"}}
                  onClick={e => {
                    e.stopPropagation();
                    handleAddItem(item.key);
                  }}
                >
                  <AddIcon />
                </IconButton>
              )}
            </>
          )}
        </Menu.Item>
      );
    });
  };

  return (
    <PxGridItem>
      <Menu
        onClick={handleClick}
        mode="vertical"
        openKeys={openKeys}
        onOpenChange={keys => setOpenKeys(keys as string[])}
        style={{minWidth: 290, maxWidth: 330, padding: 10}}
        triggerSubMenuAction="click"
      >
        {renderMenuItems(items)}
        {editTarget === "" && <EditFiled newItemTitle={newItemTitle} setNewItemTitle={setNewItemTitle} handleAddItemConfirm={handleAddItemConfirm} handleAddItemCancel={handleAddItemCancel} />}
        <div style={{display: "flex", justifyContent: "center"}}>
          <PxButton backgroundcolor="purple" onClick={() => handleAddItem()}>
            <ButtonTypo>키워드 추가</ButtonTypo>
          </PxButton>
        </div>
      </Menu>
    </PxGridItem>
  );
};

export default Category;

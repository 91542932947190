import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {Grid, Tab, Tabs, TypographyProps} from "@material-ui/core";
import SubHeadingTypo from "../../../../Typhography/SubHeadingTypo";
import styled from "styled-components";
import OutlinedTxtField from "../../../../Inputs/OutlinedTxtField";
import {useTranslation} from "react-i18next";
import MailEditor from "../MailEditor";
import Body1Typo from "../../../../Typhography/Body1Typo";
import PxEditor from "../../../../Editor/PxEditor";
import {MailInfo, MailTitleAndContent} from "../../../../../types/models/Mail";
import {useSelector} from "react-redux";
import {AppState} from "../../../../../store";

type Props = {
  handleOnChange: (
    e: React.ChangeEvent<{
      value: unknown;
      name?: string | undefined;
    }>
  ) => void;
  mail: MailInfo;
  setMail: Dispatch<SetStateAction<MailInfo>>;
  // mailTitleAndContent: MailTitleAndContent | undefined;
  // setMailTitleAndContent: Dispatch<SetStateAction<MailTitleAndContent>> | undefined;
};

enum MailContentType {
  COMMON = "common",
  TEMPLATE = "template",
}

const SubTitle: React.FC<TypographyProps> = styled((props: TypographyProps) => {
  const {...rest} = props;
  return <SubHeadingTypo {...rest} />;
})`
  margin: 32px 0 8px 0;
  font-weight: bold;
`;

const MailSendEditor: React.FC<Props> = ({handleOnChange, mail, setMail}) => {
  const [t] = useTranslation("lang", {useSuspense: false});
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const [contentType, setContentType] = useState<MailContentType>(MailContentType.COMMON);
  const [commonContent, setCommonContent] = useState<string>("");
  const [templateContent, setTemplateContent] = useState<string>("");

  useEffect(() => {
    if (contentType === MailContentType.TEMPLATE) {
      // setMailTitleAndContent({...mailTitleAndContent, content: templateContent});
      setMail({...mail, content: templateContent});
    } else {
      // setMailTitleAndContent({...mailTitleAndContent, content: commonContent});
      setMail({...mail, content: commonContent});
    }
  }, [contentType, templateContent, commonContent]);

  const handleTabChange = (event: React.ChangeEvent<{}>, tabValue: MailContentType) => {
    setContentType(tabValue);
    // window.localStorage.setItem("mailTab", JSON.stringify(newTapNumber));
  };

  return (
    <>
      <SubTitle> {t("common.title")}</SubTitle>
      <Grid container direction="row">
        <OutlinedTxtField fullWidth onChange={handleOnChange} name="title" value={mail.title} placeholder={t("mailAdd.typeTheTitle")} />
      </Grid>
      <SubTitle> {t("common.content")}</SubTitle>
      <Tabs value={contentType} onChange={handleTabChange} TabIndicatorProps={{style: {backgroundColor: "purple"}}} style={{marginBottom: "20px"}}>
        <Tab label="일반" value={MailContentType.COMMON} />
        <Tab label="템플릿" value={MailContentType.TEMPLATE} />
      </Tabs>
      {contentType === MailContentType.COMMON && (
        <div>
          <Body1Typo>{t("mailAdd.contentTip")}</Body1Typo>
          <PxEditor value={commonContent} onChange={setCommonContent} folderPath="event/" placeholder={t("mailAdd.typeTheContent")} />
        </div>
      )}
      {contentType === MailContentType.TEMPLATE && subEvent?.mainEvent && (
        <>
          <MailEditor
            eventId={subEvent?.mainEvent?.id as number}
            eventMainImage={subEvent.mainEvent?.image == "" ? subEvent.mainEvent.mainImageFile.filePath : subEvent.mainEvent?.image}
            logoImage={subEvent.mainEvent?.logoImageFile.filePath}
            setTemplateContent={setTemplateContent}
          />
        </>
      )}
    </>
  );
};

export default MailSendEditor;

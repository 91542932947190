import React, {useState, useEffect, useMemo, useCallback} from "react";
import uuid from "uuid";
import {useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../../store";
import {getForms, getHistoryForm, getHistoryBuyerForm, updateForm} from "../../../../actions/forms";
import {useTranslation} from "react-i18next";

// types
import {Form, FormElement} from "../../../../types/models/Form";

// 자율양식 form component
import AutoForm from "../../autonomous/AutoForm";

import useFormContext from "../../../../components/Provider/FormProvider/useFormContext";
import FixNotice from "../../../../components/SubEvent/InsideMenu/Notice/FixNotice";
import {InputLabel, MenuItem} from "@material-ui/core";
import PxOutlinedFormControl from "../../../../components/Forms/PxOutlinedFormControl";
import PxSelectBox from "../../../../components/SelectBox/PxSelectBox";
import {exportBgroup, irBgroup, jobBgroup, tourBgroup} from "../template/templateData";

const BuyerPartiForm = () => {
  const [t] = useTranslation("lang", {useSuspense: false});
  const {initialLoad, setInitialLoad} = useFormContext();
  const dispatch = useDispatch();
  const params = useParams<{id: string}>(); // subEvent id
  const {subEvent} = useSelector((state: AppState) => state.subEvents);
  const forms = useSelector((state: AppState) => state.forms);
  const userId = useSelector((state: AppState) => state.users.id);
  const eventHistory = useSelector((state: AppState) => state.forms.history);
  const [defaultData, setDefaultData] = useState<Form>();
  const [selectValue, setSelectValue] = useState<string | undefined>();
  const [formInitial, setFormInitial] = useState<boolean>(false);
  const [updatedData, setUpdatedData] = useState<any>();
  const history = useHistory();

  const extractTextPattern = /(<([^>]+)>)/gi;

  const defaultFormData = [
    {
      title: t("registeringForm.typeCorporatreName"),
      type: "short",
      explainable: true,
    },
    {
      title: t("registeringForm.interestCategory"),
      type: "category",
      explainable: true,
    },
    {
      title: t("registeringForm.typeKeywords"),
      type: "keyword",
      explainable: true,
    },
    {
      title: t("registeringForm.selectCategory"),
      type: "single",
      explainable: true,
      keywordOption: [],
    },
  ];

  let templateData: FormElement[] = defaultFormData;

  // 이미 등록한 양식이 있으면 db에서 불러옴
  useEffect(() => {
    if (subEvent?.buyerPartiForm !== undefined && subEvent.buyerPartiForm !== 0 && initialLoad) {
      dispatch(getForms(subEvent.buyerPartiForm, Number(subEvent.id)));
      setInitialLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subEvent]);

  useEffect(() => {
    if (userId !== undefined) {
      dispatch(getHistoryBuyerForm(Number(userId)));
    }
  }, [userId]);

  // db에서 불러온 양식을 가공하여 자율양식 form으로 만들어줌
  useEffect(() => {
    if (forms.content !== "" && forms.content !== undefined) {
      const data: Form = {
        id: forms.id,
        type: forms.type,
        content: "",
        subEventId: parseInt(params.id),
      };
      setDefaultData({
        ...data,
        formData: JSON.parse(forms.content!),
        additionalFormData: JSON.parse(forms.additional!),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forms]);

  const makeDefaultFormData = useCallback(() => {
    if (formInitial) {
      setFormInitial(false);
      setDefaultData(undefined);
    }
    const map: Form = {
      type: "BYP",
      formData: {formData: {}, formIds: []},
      content: "",
      subEventId: parseInt(params.id),
    };

    switch (forms.templateType) {
      case "export":
        templateData = exportBgroup;
        break;
      case "ir":
        templateData = irBgroup;
        break;
      case "job":
        templateData = jobBgroup;
        break;
      case "tour":
        templateData = tourBgroup;
        break;
    }

    const updateFormData = templateData.map(v => {
      const formId = `form-${uuid.v4()}`;
      let formIds = [formId];
      map.formData!.formIds.push(formId);

      let formData = {
        [formId]: {
          id: formId,
          explain: "",
          index: 0,
        },
      };

      if (v.content) {
        const answerData = v.content?.map((item, index) => {
          const answerId = `form-${uuid.v4()}`;
          index == 0 ? (formIds = [answerId]) : formIds.push(answerId);

          return {
            [answerId]: {
              id: answerId,
              explain: item,
              index: index,
            },
          };
        });

        formData = Object.assign({}, ...answerData);
      }

      return {
        [formId]: {
          id: formId,
          index: 0,
          type: v.type,
          selectable: v.title == "카테고리" || v.title == "키워드" ? false : true,
          optionable: true,
          title: v.title,
          explainable: true,
          secretable: true,
          requirable: true,
          explain: v.explain,
          secretCheck: "N",
          explainCheck: v.explainable ? "Y" : "N",
          requireCheck: v.required ? "Y" : "N",
          selectContent: {
            formData,
            formIds: formIds,
          },
          content: "",
          keywordOptions: v.keywordOption,
        },
      };
    });

    Object.assign(map.formData!.formData, ...updateFormData);
    return map;
  }, [forms]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialData = useMemo(() => makeDefaultFormData(), [params.id, formInitial]);

  const historyFormSelect = async (event: React.ChangeEvent<{name: string | undefined; value: unknown}>) => {
    const formId = (event.target.value as string).split("|")[0];
    setSelectValue(event.target.value as string);
    if (event.target.value == "default") {
      setFormInitial(true);
    } else {
      dispatch(updateForm(Number(formId)));
      setInitialLoad(false);
    }
  };

  return (
    <>
      <FixNotice />
      {/* {history && (
        <>
          <InputLabel id="demo-simple-select-outlined-label">지난 이벤트 양식 불러오기</InputLabel>
          <PxOutlinedFormControl fullWidth>
            <PxSelectBox
              name="category"
              value={selectValue}
              onChange={(e: any) => {
                historyFormSelect(e);
              }}
              displayEmpty
            >
              <MenuItem value="default">기본 양식</MenuItem>
              {eventHistory?.map((item, key) => {
                return (
                  <MenuItem key={key} value={`${item.formId}|${item.eventTitle}`}>
                    {item.eventTitle.replace(extractTextPattern, "")}
                  </MenuItem>
                );
              })}
            </PxSelectBox>
          </PxOutlinedFormControl>
        </>
      )} */}
      {defaultData !== undefined && <AutoForm initialFormData={defaultData} />}
      {defaultData === undefined && <AutoForm initialFormData={initialData} />}
    </>
  );
};

export default BuyerPartiForm;

import React, {useEffect} from "react";

export type RowData = {
  applicationId: string;
  approval: string;
  category: string;
  company: string;
  email: string;
  keyword: string;
  method: string;
  name: string;
  type: string;
};

type TableRowProps = {
  row: RowData;
  isChecked: boolean;
  onToggle: (id: string) => void;
};

const MailUserRow: React.FC<TableRowProps> = React.memo(({row, isChecked, onToggle}) => {
  return (
    <tr>
      <td>
        <input type="checkbox" checked={isChecked} onChange={() => onToggle(row.applicationId)} />
      </td>
      <td>{row.type}</td>
      <td>{row.email}</td>
      <td>{row.company}</td>
      <td>{row.name}</td>
    </tr>
  );
});

export default MailUserRow;

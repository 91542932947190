import MaterialTable, {Column, MTableToolbar} from "material-table";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {customTheme, StyledDiv, tableIcons} from "../../../../Table/PxTable";
import {makeStyles, MuiThemeProvider} from "@material-ui/core/styles";
import PxButton from "../../../../Buttons/PxButton";
import ButtonTypo from "../../../../Typhography/ButtonTypo";
import Box from "@material-ui/core/Box";
import {getRegitUser} from "../../../../../actions/registration";
import {useHistory, useParams} from "react-router";
import {AppState} from "../../../../../store";
import {useTranslation} from "react-i18next";
import {printLabel} from "../../../../../actions/deskPrint";
import Alert from "../../../../../types/models/Alert";
import {removeAlertAction, setAlertAction} from "../../../../../actions/alerts";
import {RegitUser} from "../../../../../types/models/Registration";
import {getPrinterAction, getPrinters} from "../../../../../actions/printers";
import Container from "@material-ui/core/Container";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import PxSelectBox from "../../../../SelectBox/PxSelectBox";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import PxIconButton from "../../../../Buttons/PxIconButton";
import PrintIcon from "@material-ui/icons/Print";
import {AutonoForm, FormData} from "../../../../../types/models/AutonoForm";
import {getForms} from "../../../../../actions/forms";
import styled from "styled-components";
import PxOutlinedFormControl from "../../../../Forms/PxOutlinedFormControl";
import TableAndSearchField from "./TableAndSearchField";

const useStyles = makeStyles(() => ({
  tableToolbarRoot: {
    justifyContent: "center",
    "& fieldset": {
      borderRadius: 28,
      borderColor: "#e0e0e0",
    },
  },
  tableToolbarSpacer: {
    flex: "none",
  },
  tableSearchField: {
    minWidth: 420,
  },
  container: {
    marginTop: 20,
  },
  buttonRoot: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 30,
  },
  formControl: {
    minWidth: 150,
  },
}));

const RegitApplicationList = () => {
  const classes = useStyles();
  const [t] = useTranslation("lang", {useSuspense: false});
  const dispatch = useDispatch();
  const params = useParams<{id: string}>();
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const {printers, printer} = useSelector((state: AppState) => state.printers);
  const [loading, setLoading] = useState<boolean>(false);
  const [tableRefresh, setTableRefresh] = useState<boolean>(false);
  const forms = useSelector((state: AppState) => state.forms);
  const regitUsers = useSelector((state: AppState) => state.applicants.regitUsers);
  const [typeList, setTypeList] = useState<string[]>([]);

  useEffect(() => {
    if (forms.content == "" && subEvent?.registerForm) {
      dispatch(getForms(subEvent.registerForm, Number(subEvent.id)));
    } else if (forms.content !== "") {
      const formData: FormData = JSON.parse(forms.content as string);
      const typeData: (string | undefined)[] | undefined = getTypeList(formData);

      if (typeData == undefined) return;
      setTypeList(typeData as string[]);
    }
  }, [forms, subEvent]);

  const getTypeList = (formData: FormData): (string | undefined)[] => {
    const formItem = Object.values(formData.formData).find(item => item.title == "분류");
    if (formItem == undefined) return [];
    const selectContent: FormData = formItem.selectContent as FormData;

    return selectContent.formIds.map(id => selectContent.formData[id].explain);
  };

  useEffect(() => {
    dispatch(getPrinters());
    // const callGetRegitUser = async () => {
    //   if (regitUsers === undefined) setLoading(true);
    //   await dispatch(getRegitUser(params.id));
    // };
    setLoading(false);
    // callGetRegitUser();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, params.id]);

  const onClickApplicantPrint = (rowData: RegitUser) => {
    if (printer && subEvent) {
      printLabel({
        applicantId: Number(rowData.id),
        dispatch,
        eventName: subEvent.mainEvent!.name,
        companyName: rowData.companyName,
        position: rowData.position,
        name: rowData.name,
        type: rowData.type,
        ipAddress: printer.ipAddress,
        printerName: printer.name,
      });
    } else {
      const alert: Alert = {
        id: "noPrinterAlert",
        msg: "프린터를 선택해주세요.",
        alertType: "warning",
      };
      dispatch(setAlertAction(alert));
      setTimeout(() => {
        dispatch(removeAlertAction(alert.id));
      });
    }
  };

  const printerOnChange = (event: React.ChangeEvent<{value: unknown}>) => {
    if (printers) {
      setTableRefresh(!tableRefresh);
      dispatch(getPrinterAction(printers.find(printer => printer.id === event.target.value)!));
    }
  };

  const onClickTestPrint = () => {
    if (subEvent?.mainEvent && printer) {
      printLabel({
        applicantId: 0,
        dispatch,
        eventName: subEvent.mainEvent.name,
        companyName: "테스트 회사",
        name: "테 스 형",
        position: "직 함",
        type: "타입",
        ipAddress: printer.ipAddress,
        printerName: printer.name,
      });
    } else {
      const alert: Alert = {
        id: "noPrinterAlert",
        msg: "프린터를 선택해주세요.",
        alertType: "warning",
      };
      dispatch(setAlertAction(alert));
      setTimeout(() => {
        dispatch(removeAlertAction(alert.id));
      });
    }
  };

  return (
    <Container className={classes.container}>
      <Backdrop open={loading} style={{zIndex: 1000, color: "#fff"}}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className={classes.buttonRoot}>
        {printers && (
          <FormControl className={classes.formControl}>
            <PxSelectBox input={<OutlinedInput margin="dense" style={{padding: 3}} />} onChange={printerOnChange}>
              {printers.map(printer => (
                <MenuItem value={printer.id}>{printer.name}</MenuItem>
              ))}
            </PxSelectBox>
          </FormControl>
        )}
        <Box width={10} />
        <PxButton backgroundcolor="darkGrey" padding="10px 15px" onClick={onClickTestPrint}>
          <ButtonTypo>인쇄 테스트</ButtonTypo>
        </PxButton>
        <Box width={10} />
        <PxIconButton
          onClick={() => {
            window.open(`/printer/${params.id}`, "_blank");
          }}
        >
          <PrintIcon />
        </PxIconButton>
        {/* <PxButton
          backgroundcolor="darkGrey"
          padding="10px 15px"
          onClick={onClickTestPrint}
        >
          <ButtonTypo></ButtonTypo>
        </PxButton> */}
      </div>
      {tableRefresh && typeList && <TableAndSearchField onClickApplicantPrint={onClickApplicantPrint} typeList={typeList} />}
      {tableRefresh === false && typeList && <TableAndSearchField onClickApplicantPrint={onClickApplicantPrint} typeList={typeList} />}
    </Container>
  );
};

export default RegitApplicationList;

import {Button, Chip, FormControl, FormGroup, RadioGroup} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import {toInteger} from "lodash";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {getSignedRequest} from "../../../../../actions/files";
import {AppState} from "../../../../../store";
import {AutonoForm, FormData} from "../../../../../types/models/AutonoForm";
import {RegisterFormAnswer} from "../../../../../types/models/Registration";
import PxButton from "../../../../Buttons/PxButton";
import ShowFile from "../../../../Common/ShowFile";
import PxGridContainer from "../../../../Grid/PxGridContainer";
import PxGridItem from "../../../../Grid/PxGridItem";
import PxOutlinedTxtField from "../../../../Inputs/PxOutlinedTxtField";
import ButtonTypo from "../../../../Typhography/ButtonTypo";
import SubHeadingTypo from "../../../../Typhography/SubHeadingTypo";
import TranslatedFormItem from "../CompanyInfo/TranslatedFormItem";
import {useRegisterInfoContext} from "./userDetail/RegisterInfoProvider/useRegisterInfoContext";

interface Props {
  contentFormData: AutonoForm; // 신청서 양식 데이터
  modify: boolean; // 보기 or 수정 상태
  formAnswer: RegisterFormAnswer; // 자율양식 신청서 답변
  manyFormAnswers: RegisterFormAnswer[]; // 복수선택 신청서 답변
}

const RegisterFormItem: React.FC<Props> = ({contentFormData, modify, formAnswer, manyFormAnswers}) => {
  const {id, type, title, explain, explainCheck, requireCheck, selectContent} = contentFormData;
  const dispatch = useDispatch();
  const params: {id: string} = useParams(); // id: subEvent id
  const forms = useSelector((state: AppState) => state.forms);
  const {modifyContentAnswers, formId, modifyManyContentAnswers, modifiedFormAnswers} = useRegisterInfoContext();

  // 자율양식 신청서 데이터
  const [textContent, setTextContent] = useState<string>(""); // 신청서 url, short, long type data
  const [keywordData, setKeywordData] = useState<string[]>([]); // 신청서 keyword data string 배열 data
  const [keywordOption, setKeywordOption] = useState<string[]>([]); // 양식에 등록한 keyword option data
  const [keywordInputValue, setKeywordInputValue] = useState<string>(""); // 키워드필드의 input 값
  const [radioValue, setRadioValue] = useState("");
  const [checkBoxValue, setCheckBoxValue] = useState<{
    [key: string]: boolean;
  }>({});
  const [fileNameValue, setFileNameValue] = useState<string | undefined>();

  const [modifiedProductImg, setModifiedProductImg] = useState<number>();

  useEffect(() => {
    if (forms.content == null) return;
    const formData: FormData = JSON.parse(forms.content);
    const keywordForm = formData.formIds.map(formId => formData.formData[formId]).find(form => form.title === "키워드");
    // setKeywordOption(keywordForm?.keywordOptions as string[]);
  }, [forms]);

  useEffect(() => {
    if (formAnswer != null) {
      switch (type) {
        case "keyword": {
          if (formAnswer.content !== undefined) {
            setKeywordData(formAnswer.content.split(","));
          }
          break;
        }
        case "category": {
          if (formAnswer.content !== null && formAnswer.content !== undefined) {
            setRadioValue(formAnswer.answerUuid + "[S]" + formAnswer.content);
          }
          break;
        }
        case "single": {
          if (formAnswer !== undefined && formAnswer.content !== null) {
            setRadioValue(formAnswer.answerUuid + "[S]" + formAnswer.content);
          }
          break;
        }
        case "many": {
          let defaultSet = {};
          let checkBoxContentText = "";

          if (manyFormAnswers.length !== 0) {
            manyFormAnswers.forEach(value => {
              checkBoxContentText += value.answerUuid + "[S]" + value.content + "&&";
            });

            selectContent!.formIds.forEach(formId => {
              const key = `${formId}[S]${selectContent!.formData[formId].explain!}`;
              Object.assign(defaultSet, {
                [key]: checkBoxContentText.indexOf(key) > -1 ? true : false,
              });
            });
            setCheckBoxValue(defaultSet);
          }
          break;
        }
        case "file": {
          if (formAnswer !== undefined) {
            setFileNameValue(formAnswer.content);
          }
          break;
        }
        default: {
          if (formAnswer !== undefined) {
            setTextContent(formAnswer.content!);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, formAnswer, modify]);

  // 단일선택 답변 onChange method
  const handleRadioOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioValue((event.target as HTMLInputElement).value);
    modifyContentAnswers(formAnswer, event.target.value);
  };

  // 단문 && 장문 && url onChange method
  const handleTextInputOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTextContent((event.target as HTMLInputElement).value);
    console.log(`formAnswer : `, formAnswer);
    modifyContentAnswers(formAnswer, event.target.value);
  };

  // 키워드 onChange method
  const handleKeywordOnChange = (event: React.ChangeEvent<{}>, value: string[]) => {
    event.preventDefault();
    modifyContentAnswers(formAnswer, value.toString());
    setKeywordInputValue("");
  };

  const handleCheckBoxOnChange = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    e.preventDefault();
    setCheckBoxValue(prevCheckBoxValue => {
      Object.assign(prevCheckBoxValue, {
        [`${e.target.name}`]: checked,
      });
      return {...prevCheckBoxValue};
    });

    const newApplicationFormAnswers: RegisterFormAnswer[] = [];
    Object.entries(checkBoxValue).forEach(value => {
      if (value[1] === true) {
        const splitedKey = value[0].split("[S]"); // 0 : answerUuid, 1: content
        const newApplicationFormAnswer: RegisterFormAnswer = {
          formId: formId!,
          answerUuid: splitedKey[0],
          title: contentFormData.title!,
          type: type!,
          uuid: contentFormData.id,
          content: splitedKey[1],
        };
        newApplicationFormAnswers.push(newApplicationFormAnswer);
      }
    });

    modifyManyContentAnswers(contentFormData.id, newApplicationFormAnswers);
  };

  // 파일 삭제 method
  const handleFileDelete = () => {
    setFileNameValue(undefined);
  };

  // 파일 등록 method
  const handleFileAdd = async (
    e: React.ChangeEvent<{
      files: any;
      name?: string | undefined;
    }>,
    isProductImg?: boolean
  ) => {
    e.preventDefault();

    const file = e.target.files[0];
    const fileParts = file.name.split(".");
    const originalFileName = fileParts[0];
    const timestamp = new Date().getTime();
    const fileType = fileParts[fileParts.length - 1];
    const fileName = originalFileName + "&&" + timestamp + "." + fileType;
    const folderPath = `event/${params.id}/`;
    const fileSize = file.size;

    const result: any = await dispatch(getSignedRequest(file, fileName, fileType, folderPath, fileSize, ""));
    if (result?.data) {
      modifyContentAnswers(formAnswer, `${result.data.id}`);
    }
  };

  const urlClickEvent = (url: string) => {
    if (url !== "") {
      if (!url.includes("https://") && !url.includes("http://")) {
        window.open("http://" + url);
      } else {
        window.open(url);
      }
    }
  };

  return (
    <PxGridContainer className="py-3" direction="column">
      {/* 질문 */}
      <PxGridItem>
        <SubHeadingTypo fontweight="bold">
          <TranslatedFormItem
            type="input"
            content={`${title} ${requireCheck === "Y" ? " (필수)" : ""}`} //기업명, 카테고리, 키워드 번역
            name={id}
            fullWidth
            fontsize={15}
            readOnly={true}
            fontweight="bold"
            link={modify}
          />
        </SubHeadingTypo>
      </PxGridItem>
      {/* ///////////////// */}
      {/* 설명 */}
      {explainCheck === "Y" && (
        <PxGridItem>
          <TranslatedFormItem type="string" content={explain!} name={id} fullWidth fontsize={14} fontweight="bold" readOnly link={modify} />
        </PxGridItem>
      )}
      {/* //////////////////// */}
      {/* 장문, 단문, url */}
      {(type === "long" || type === "short" || type === "url") && (
        <PxGridItem>
          <PxOutlinedTxtField
            name={id}
            value={textContent === null ? "" : textContent!}
            // disabled={title === "기업명" || title === "소속명" || !modify}
            disabled={!modify}
            inputpadding={type === "long" ? 0 : 10}
            multiline={type === "long" ? true : false}
            cursor={type === "url" ? "pointer" : "default"}
            onClick={() => {
              type === "url" && !modify && urlClickEvent(textContent);
            }}
            rows={2}
            fullWidth
            placeholder={"입력해주세요."}
            fontsize={14}
            onChange={handleTextInputOnChange}
          />
        </PxGridItem>
      )}

      {/* ///////////////////////////// */}
      {/* 키워드 - 태그들의 값이 일반보기 페이지와 수정페이지간의 변경이 unControlled component에서는 원활히 이루어지지 않아 어쩔 수 없이 일반보기와 수정일때를 분리하여 keyword 양식이 나오도록 함 */}
      {/* 일반보기 */}
      {type === "keyword" && modify === false && (
        <PxGridItem>
          <Autocomplete
            multiple
            freeSolo
            options={keywordData.map(keyword => keyword)}
            disabled={true}
            id="keyword-list"
            inputValue={keywordInputValue}
            defaultValue={keywordData.map<string>(keyword => keyword)}
            renderTags={(value: string[], getTagProps) => {
              // 일반 보기페이지와 수정페이지를 구분해서 tag들을 보여줌
              if (formAnswer !== undefined) {
                const keywords = formAnswer.content?.split(",");
                return keywords!.map((option: string, index: number) => <Chip key={index} variant="outlined" label={option} {...getTagProps({index})} />);
              }
            }}
            renderInput={params => (
              <PxOutlinedTxtField
                {...params}
                inputProps={{...params.inputProps, style: {padding: 0}}}
                fullWidth
                disabled={!modify}
                placeholder={"키워드 입력 후 엔터를 눌러주세요."} // 등록정보 보기 페이지에서는 안내문구 필요x
                fontsize={14}
              />
            )}
          />
        </PxGridItem>
      )}
      {/* 키워드 수정 */}
      {type === "keyword" && modify === true && (
        <PxGridItem>
          <Autocomplete
            multiple
            freeSolo
            options={keywordOption.map(keyword => keyword)}
            disabled={false}
            id="keyword-list"
            onChange={handleKeywordOnChange}
            onInputChange={(event, value) => {
              if (value.length <= 20)
                if (/\s/.test(value) === false) {
                  setKeywordInputValue(value.toLocaleLowerCase());
                }
            }}
            inputValue={keywordInputValue}
            defaultValue={keywordData.map<string>(keyword => keyword)}
            renderTags={(value: string[], getTagProps) => {
              // 일반 보기페이지와 수정페이지를 구분해서 tag들을 보여줌
              return value.map((option: string, index: number) => <Chip key={index} variant="outlined" label={option} {...getTagProps({index})} />);
            }}
            renderInput={params => (
              <PxOutlinedTxtField
                {...params}
                inputProps={{...params.inputProps, style: {padding: 0}}}
                fullWidth
                disabled={!modify}
                placeholder={""} // 등록정보 보기 페이지에서는 안내문구 필요x
                fontsize={14}
              />
            )}
          />
        </PxGridItem>
      )}
      {/* /////////////////////////////////////////////// */}
      {/* 카테고리 or 단일선택 */}
      {(type === "single" || type === "category") && (
        <PxGridItem>
          <FormControl>
            {/* radio button value는  */}
            <RadioGroup value={radioValue} onChange={handleRadioOnChange}>
              {console.log(`title : `, title)}
              {selectContent!.formIds.map((formId, index) => {
                // 등록정보 보기 페이지에서는 선택한 답변만 나와야함
                // modify => false: 일반 등록정보 보기, false: 수정 페이지
                if (modify === false) {
                  if (formAnswer !== undefined && formAnswer.answerUuid === selectContent!.formData[formId].id) {
                    return (
                      <TranslatedFormItem
                        key={index}
                        type="radio"
                        content={selectContent!.formData[formId].explain!}
                        disabled={!modify}
                        value={`${formId}[S]${selectContent!.formData[formId].explain!}`}
                        link={modify}
                      />
                    );
                  }
                } else {
                  return (
                    <TranslatedFormItem
                      key={index}
                      type="radio"
                      content={selectContent!.formData[formId].explain!}
                      disabled={!modify}
                      value={`${formId}[S]${selectContent!.formData[formId].explain!}`}
                      link={modify}
                    />
                  );
                }
                return null;
              })}
            </RadioGroup>
          </FormControl>
        </PxGridItem>
      )}
      {/* /////////////////////////////////// */}
      {/* 복수선택 */}
      {type === "many" && (
        <PxGridItem>
          <FormControl>
            <FormGroup>
              {selectContent!.formIds.map((formId, index) => {
                // 등록정보 보기 페이지에서는 선택한 답변만 나와야함
                // modify => false: 일반 등록정보 보기, false: 수정 페이지
                // 등록정보 보기 페이지에서는 checkBoxValue 배열에 있는 key의 값이 true인것만 출력하도록 함
                if (modify === false) {
                  if (checkBoxValue[`${formId}[S]${selectContent!.formData[formId].explain!}`] === true) {
                    return (
                      <TranslatedFormItem
                        key={index}
                        type="checkbox"
                        content={selectContent!.formData[formId].explain!}
                        disabled={!modify}
                        checked={checkBoxValue[`${formId}[S]${selectContent!.formData[formId].explain!}`]}
                        name={`${formId}[S]${selectContent!.formData[formId].explain!}`}
                        link={modify}
                      />
                    );
                  }
                } else {
                  return (
                    <TranslatedFormItem
                      key={index}
                      type="checkbox"
                      content={selectContent!.formData[formId].explain!}
                      disabled={!modify}
                      checked={checkBoxValue[`${formId}[S]${selectContent!.formData[formId].explain!}`]}
                      name={`${formId}[S]${selectContent!.formData[formId].explain!}`}
                      onChange={handleCheckBoxOnChange}
                      link={modify}
                    />
                  );
                }
                return null;
              })}
            </FormGroup>
          </FormControl>
        </PxGridItem>
      )}
      {/* /////////////////////////////// */}
      {/* 파일 */}
      {type === "file" && (
        <PxGridItem>
          {fileNameValue !== undefined && fileNameValue !== null && fileNameValue !== "" ? (
            <>
              <ShowFile fileId={toInteger(fileNameValue)} />
              {modify === true && (
                <PxButton backgroundcolor="grey" onClick={handleFileDelete}>
                  <ButtonTypo>삭제</ButtonTypo>
                </PxButton>
              )}
            </>
          ) : (
            <>
              {modify === true && (
                <Button
                  variant="contained"
                  component="label"
                  style={{
                    boxShadow: "none",
                    backgroundColor: "#eee",
                    padding: "9px 16px",
                  }}
                >
                  추가
                  <input type="file" style={{display: "none"}} name="file1" onChange={handleFileAdd} />
                </Button>
              )}
            </>
          )}
        </PxGridItem>
      )}

      {/* ////////////////////////////////////// */}
    </PxGridContainer>
  );
};

export default RegisterFormItem;

import React, {ReactElement, useEffect, useMemo} from "react";
import {DragDropContext, DropResult, Droppable, Draggable} from "react-beautiful-dnd";

// types
import {DraggableItemComp, FormData} from "../../../types/models/AutonoForm";
import {useLocation} from "react-router-dom";
import {FormDataType} from "./AutoForm";

interface AutonomousFormProps {
  colunmId: string; // droppable container column Id
  selectContent?: boolean; // 단수나 복수 콘텐츠 drag & drop 인지 구분
  formId?: string; // 단수나 복수 콘텐츠 정렬 시 initialData formData의 id값 필요
  initialData: FormData | undefined; // draggable items data
  setInitialData: React.Dispatch<React.SetStateAction<FormData>>; // draggable items setState
  draggableComp: ReactElement<DraggableItemComp>; // draggable component
}

const AutonomousForm: React.FC<AutonomousFormProps> = props => {
  const {initialData, colunmId, setInitialData, selectContent, formId} = props;
  const [selected, setSelected] = React.useState<string>("");
  const location = useLocation();

  // 드래그앤드랍 컴포넌트 재정렬
  const reorder = (list: FormData, startIndex: number, endIndex: number) => {
    const result = Array.from(list.formIds);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: DropResult) => {
    const {destination, source} = result;
    if (!destination) {
      return;
    }
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }
    // 셀러 참가양식일때 기업명, 카테고리, 키워드는 재정렬불가
    if (location.pathname.includes("sellerPartiForm")) {
      if (destination?.index === 0 || destination?.index === 1 || destination?.index === 2) {
        return;
      }
    }
    // 바이어 참가양식일때 기업명, 관심 카테고리(숨김처리), 카테고리, 키워드 재정렬불가
    if (location.pathname.includes("buyerPartiForm")) {
      if (destination?.index === 0 || destination?.index === 1 || destination?.index === 2 || destination?.index === 3) {
        return;
      }
    }

    const newdata = reorder(initialData!, source.index, destination.index);

    selectContent === undefined
      ? setInitialData({...initialData!, formIds: newdata})
      : setInitialData(prevState => {
          const formData = prevState.formData;
          // return { ...prevState, selectContent: { formIds } };
          Object.assign(formData[formId!].selectContent?.formIds, newdata);
          newdata.forEach((value, index) => {
            formData[formId!].selectContent!.formData[value!].index = index;
          });

          return {...prevState, formData};
        });
  };

  const formDivStyle = useMemo(
    () => ({
      width: "100%",
    }),
    []
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={colunmId}>
        {provided => (
          <>
            <div ref={provided.innerRef} style={formDivStyle}>
              {initialData!.formIds.map((formId, index) => {
                // 바이어 참가양식에서 셀러참가 양식에서 작성한 카테고리 양식을 안보이게 하기 위함
                if (initialData?.formData[formId].type === "category" && location.pathname.includes("buyerPartiForm")) {
                  return null;
                } else if (initialData?.formData[formId].title === "소속명") {
                  return null;
                } else {
                  return (
                    <Draggable key={initialData!.formData[formId].id} draggableId={initialData!.formData[formId].id} index={index}>
                      {provided => (
                        <div {...provided.draggableProps} ref={provided.innerRef}>
                          {React.cloneElement(props.draggableComp, {
                            key: initialData!.formData[formId].id,
                            id: initialData!.formData[formId].id, // select content form 일때는 select content form 의 id, 전체 form 경우는 전체 form의 id
                            formId: props.formId, // select content form 일때 select content form의 부모 form id
                            index: index,
                            elevation: selected === initialData!.formData[formId].id ? 10 : 0,
                            setSelected: setSelected,
                            draghandle: provided.dragHandleProps,
                            formDelete: props.draggableComp.props.formDelete,
                            formCopy: props.draggableComp.props.formCopy,
                            selectContentFormAdd: props.draggableComp.props.selectContentFormAdd,
                            selectContentFormDelete: props.draggableComp.props.selectContentFormDelete,
                            formData: initialData!.formData[formId],
                            initialData: initialData,
                            setInitialData: props.draggableComp.props.setInitialData,
                          })}
                        </div>
                      )}
                    </Draggable>
                  );
                }
              })}
            </div>
            {provided.placeholder}
          </>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default AutonomousForm;

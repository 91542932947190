import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {AppState} from "../../../../../store";
import CompanyInfoAdditional from "./CompanyInfoAdditional";
import CompanyInfoContent from "./CompanyInfoContent";
import CompanyManagerInfo from "./CompanyManagerInfo";
import {useCompanyInfoContext} from "./useCompanyInfoContext";
import BusinessCard from "../../../../../types/models/BusinessCard";

const CompanyInfoView = () => {
  const {activeStep} = useCompanyInfoContext();
  const applicants = useSelector((state: AppState) => state.applications.application?.applicants);
  const [businessCards, setBusinessCards] = useState<BusinessCard[]>();
  const location = useLocation();

  useEffect(() => {
    if (applicants == undefined) return;

    if (location.pathname.includes("application")) {
      const businessCards: BusinessCard[] = applicants.map(applicant => applicant.businessCard);
      setBusinessCards(businessCards);
    }
  }, [applicants, location]);

  // activeStep 0: 기업정보, 1: 추가정보, 2: 담당자
  const renderContent = () => {
    switch (activeStep) {
      case 0:
        return <CompanyInfoContent />;
      case 1:
        return <CompanyInfoAdditional />;
      case 2:
        return businessCards && <CompanyManagerInfo businessCards={businessCards} />;
      default:
        return null;
    }
  };

  return <>{renderContent()}</>;
};

export default CompanyInfoView;
